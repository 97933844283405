.WpdConversationWrapper {
    background: $white;
    width: 100%;
    padding: 40px;
    border-radius: 25px;
    @include respond-below(xl) {
        padding: 30px;
    }
    @include respond-below(sm) {
        padding: 10px;
        border-radius: 5px;
    }
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    .form-control {
        background: $light;
    }
}

.WpdConversationGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 1000px;
    overflow: auto;
    margin-right: -30px;
    padding-right: 30px;
    @include respond-below(xl) {
        margin-right: -25px;
        padding-right: 25px;
    }
    @include respond-below(sm) {
        margin-right: -5px;
        padding-right: 5px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 6px rgba($primary, 0.1);
        background-image: linear-gradient(
            to right bottom,
            rgba($info, 0.05),
            rgba($info, 0.05)
        );
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: darken($info, 10%);
    }
    %Conversation {
        display: flex;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        .WpdConversationAuthorImage {
            height: 50px;
            width: 50px;
            min-width: 50px;
            border-radius: 50px;
            background: darken($light, 10%);
            padding: 5px;
            img {
                border-radius: 50px;
            }
            @include respond-below(lg) {
                height: 40px;
                width: 40px;
                min-width: 40px;
                border-radius: 50px;
                padding: 3px;
                img {
                    border-radius: 50px;
                }
            }
            @include respond-below(md) {
                height: 30px;
                width: 30px;
                min-width: 30px;
                padding: 2px;
                border-radius: 50px;
                img {
                    border-radius: 50px;
                }
            }
        }
        .WpdConversationMessageGroup {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            margin-right: 10px;
            width: 100%;
            max-width: 650px;
            margin-top: 25px;
            @include respond-below(md) {
                margin-top: 15px;
            }
            .WpdConversationMessage {
                font-size: 1rem;
                line-height: 1.5;
                padding: 20px 25px;
                margin-bottom: 5px;
                @include respond-below(lg) {
                    padding: 10px 20px;
                    font-size: 0.875rem;
                }
                .WpdConversationAttachmentGroup {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    margin-top: 5px;
                    .WpdConversationAttachment {
                        display: inline-flex;
                        align-items: center;
                        font-style: italic;
                        margin-top: 5px;
                        line-height: 1;
                        text-decoration: none;
                        @include respond-below(lg) {
                            margin-top: 0;
                        }
                        span {
                            text-decoration: underline;
                            margin-left: 5px;
                        }
                        &:not(:first-child) {
                            margin-left: 10px;
                        }
                    }
                }
            }
            .WpdConversationTime {
                color: $secondary;
                font-size: 0.875rem;
            }
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    .WpdSelfConversation,
    .WpdSupportConversation {
        @extend %Conversation;
    }
    .WpdSupportConversation {
        .WpdConversationMessageGroup {
            .WpdConversationMessage {
                background: linear-gradient(
                    315deg,
                    rgba($info, 0.15) 0%,
                    rgba($info-lighten, 0.15) 100%
                );
                color: $dark;
                border-radius: 0 30px 30px 0;
                @include respond-below(xl) {
                    border-radius: 0 20px 20px 0;
                }
                .WpdConversationAttachmentGroup {
                    .WpdConversationAttachment {
                        color: $sidebar;
                        &:hover {
                            color: $info;
                        }
                    }
                }
                &:last-of-type {
                    border-bottom-left-radius: 30px;
                    @include respond-below(xl) {
                        border-bottom-left-radius: 20px;
                    }
                }
            }
        }
    }
}
.WpdSelfConversation {
    flex-direction: row-reverse;
    .WpdConversationMessageGroup {
        .WpdConversationMessage {
            background: linear-gradient(315deg, $info 0%, $info-lighten 100%);
            color: set-text-color($info);
            border-radius: 30px 0 0 30px;
            @include respond-below(xl) {
                border-radius: 20px 0 0 20px;
            }
            .WpdConversationAttachmentGroup {
                .WpdConversationAttachment {
                    color: $white;
                    &:hover {
                        color: lighten($sidebar, 15%);
                    }
                }
            }
            &:last-of-type {
                border-bottom-right-radius: 30px;
                @include respond-below(xl) {
                    border-bottom-right-radius: 20px;
                }
            }
        }
        .WpdConversationTime {
            margin-left: auto;
        }
    }
    margin-left: auto;
}

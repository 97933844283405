.WpdContentArea.WpdStickyHeader {
    padding-top: 110px;
    .WpdContentAreaHeader {
        position: fixed;
        top: 0;
        right: 0;
        animation-name: WpdSlideInDown;
        animation-duration: 0.2s;
        animation-timing-function: ease;
        transition: left 0.2s cubic-bezier(1, 0.2, 0.2, 1);
        .WpdHeaderWrapper {
            padding-top: 15px;
            padding-bottom: 15px;
            background: $white;
            box-shadow: 0 5px 5px rgba($dark, 0.1);
            height: 80px;
            @include respond-below(xl) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}
@include respond-above(xl) {
    .WpdSidebar.Toggled:hover + .WpdContentArea.WpdStickyHeader,
    .WpdSidebar:not(.Toggled) + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 250px;
        }
    }
    .WpdSidebar.Toggled:not(:hover) + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 93px;
        }
    }
}
@include respond-below(xl) {
    .WpdSidebar.Toggled + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 210px;
            right: initial;
            width: calc(100% - 62px);
        }
    }
    .WpdSidebar:not(.Toggled) + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 62px;
            right: initial;
            width: calc(100% - 62px);
        }
    }
}
@include respond-below(xs) {
    .WpdSidebar.Toggled + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 210px;
            right: initial;
            width: calc(100% - 0px);
        }
    }
    .WpdSidebar:not(.Toggled) + .WpdContentArea.WpdStickyHeader {
        .WpdContentAreaHeader {
            left: 0;
            right: initial;
            width: calc(100% - 0px);
        }
    }
}

@keyframes WpdSlideInDown {
    from {
        transform: translate3d(0, -100%, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@font-face {
	font-family: 'Wpd-Icon';
	src: url('fonts/Wpd-Icon.eot?vcxy55');
	src:
		url('fonts/Wpd-Icon.eot?vcxy55#iefix') format('embedded-opentype'),
		url('fonts/Wpd-Icon.ttf?vcxy55') format('truetype'),
		url('fonts/Wpd-Icon.woff?vcxy55') format('woff'),
		url('fonts/Wpd-Icon.svg?vcxy55#Wpd-Icon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.wpd-icon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Wpd-Icon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wpd-information-solid:before {
	content: '\e92e';
}
.wpd-ellipsis:before {
	content: '\e92d';
}
.wpd-envelop:before {
	content: '\e92b';
}
.wpd-tablet:before {
	content: '\e92c';
}
.wpd-copy-2:before {
	content: '\e929';
}
.wpd-key-2:before {
	content: '\e92a';
}
.wpd-twitter-x:before {
	content: '\e928';
}
.wpd-management:before {
	content: '\e927';
}
.wpd-search:before {
	content: '\e925';
}
.wpd-swap:before {
	content: '\e926';
}
.wpd-delete:before {
	content: '\e924';
}
.wpd-left-quote:before {
	content: '\e922';
}
.wpd-check-o:before {
	content: '\e91d';
}
.wpd-credit-card:before {
	content: '\e91e';
}
.wpd-mail:before {
	content: '\e91f';
}
.wpd-paypal:before {
	content: '\e920';
}
.wpd-user2:before {
	content: '\e921';
}
.wpd-angle-left:before {
	content: '\e91a';
}
.wpd-spinner:before {
	content: '\e91c';
}
.wpd-chevron-circle-down:before {
	content: '\e91b';
}
.wpd-question:before {
	content: '\e923';
}
.wpd-toggle:before {
	content: '\e919';
}
.wpd-eye:before {
	content: '\e918';
}
.wpd-plus:before {
	content: '\e917';
}
.wpd-key:before {
	content: '\e916';
}
.wpd-warning:before {
	content: '\e915';
}
.wpd-logout:before {
	content: '\e914';
}
.wpd-star:before {
	content: '\e913';
}
.wpd-cancel:before {
	content: '\e911';
}
.wpd-tick:before {
	content: '\e912';
}
.wpd-share:before {
	content: '\e910';
}
.wpd-angle-down:before {
	content: '\e900';
}
.wpd-chart:before {
	content: '\e901';
}
.wpd-copy:before {
	content: '\e902';
}
.wpd-download:before {
	content: '\e903';
}
.wpd-download-2:before {
	content: '\e904';
}
.wpd-facebook:before {
	content: '\e905';
}
.wpd-instagram:before {
	content: '\e906';
}
.wpd-linkedin:before {
	content: '\e907';
}
.wpd-message:before {
	content: '\e908';
}
.wpd-play-button:before {
	content: '\e909';
}
.wpd-referral:before {
	content: '\e90a';
}
.wpd-shopping-bag:before {
	content: '\e90b';
}
.wpd-ticket:before {
	content: '\e90c';
}
.wpd-toggler:before {
	content: '\e90d';
}
.wpd-twitter:before {
	content: '\e90e';
}
.wpd-user:before {
	content: '\e90f';
}

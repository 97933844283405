.modal {
    &-backdrop {
        background-color: $sidebar !important;
        opacity: 0.4 !important;
    }
    &.Wpd2FAModal {
        .modal-dialog {
            max-width: 720px;
            padding-left: 10px;
            padding-right: 10px;
            margin: 10px auto;
        }
    }
    &-content {
        border: none !important;
        background: $light;
        box-shadow: 0 5px 20px rgba($dark, 0.2);
    }
    .modal-header {
        border: none;
        padding: 35px 50px 25px;
        .modal-title {
            font-weight: 500;
            line-height: 1.1;
            font-size: 1.5rem;
            color: $primary;
        }
        .close {
            height: 30px;
            width: 30px;
            min-width: 30px;
            padding: 0;
            margin: 0 0 0 auto;
            position: absolute;
            top: 10px;
            right: 10px;
            background: darken($light, 5%);
            color: darken($secondary, 35%);
            text-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            font-size: 1.5rem;
            border-radius: 4px;
            @include respond-below(hl) {
                height: 26px;
                width: 26px;
            }
            &:hover {
                background: lighten($secondary, 17%);
                color: $dark;
            }
        }
        @include respond-below(md) {
            padding: 25px 30px 15px;
        }
    }

    .modal-body {
        padding: 0 50px 25px;
        @include respond-below(md) {
            padding: 0 30px 15px;
        }
        .ModalControlGroup {
            margin-bottom: 30px;
            @include respond-below(lg) {
                margin-bottom: 20px;
            }
            .ModalTabControllers {
                display: flex;
                background: $light;
                color: $secondary;
                .ModalTabControl {
                    flex: 1;
                    font-size: 1rem;
                    padding: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 500;
                    color: $secondary;
                    @include respond-below(xxl) {
                        padding: 15px;
                    }
                    @include respond-below(lg) {
                        padding: 10px;
                    }
                    &.Active {
                        background: $primary;
                        color: $white;
                        &:hover {
                            background: $primary;
                            color: $white;
                        }
                    }
                    &.Passed {
                        color: $primary;
                        &:hover {
                            color: $primary;
                        }
                    }
                    &:hover {
                        background: darken($light, 2%);
                        color: darken($secondary, 25%);
                    }
                }
            }
            .progress {
                margin-top: 30px;
                height: 10px !important;
                @include respond-below(xxl) {
                    margin-top: 15px;
                }
                @include respond-below(lg) {
                    margin-top: 10px;
                }
                .progress-bar {
                    background: $primary !important;
                }
            }
        }
        .ActiveSubscriptionPlan {
            background: $light;
            padding: 30px;
            p.SmallButton {
                position: absolute;
                right: 30px;
                top: 30px;
            }
        }
        .ChangePlanBox {
            background: $light;
            &Title {
                padding: 30px;
            }
            &Body {
                padding: 30px;
                .TabContentRow {
                    label {
                        min-width: 20px;
                        margin-bottom: 20px;
                        @include respond-below(lg) {
                            margin-bottom: 5px;
                        }
                    }
                    &:not(:first-child) {
                        margin-top: 20px;
                        @include respond-below(lg) {
                            margin-top: 10px !important;
                        }
                    }
                }
            }
        }
        .ModalTabContent {
            background: $light;
            padding: 30px;
            @include respond-below(lg) {
                padding: 15px;
            }
            .TabContentRow {
                display: flex;
                align-items: center;
                @include respond-below(lg) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                label {
                    min-width: 220px;
                    margin-right: 20px;
                    @include respond-below(lg) {
                        min-width: 100px;
                        margin-bottom: 3px;
                    }
                }
                &:not(:first-child) {
                    margin-top: 20px;
                    @include respond-below(lg) {
                        margin-top: 10px !important;
                    }
                }
            }
        }
        .WpdDownloadItemWrapper {
            grid-template-columns: repeat(1, 1fr) !important;
        }
        &:last-child {
            padding-bottom: 50px;
            @include respond-below(md) {
                padding-bottom: 30px;
            }
        }
    }
    .modal-footer {
        padding: 0 50px 40px;
        border: none;
        @include respond-below(md) {
            padding: 0 30px 30px;
        }
        > * {
            margin: initial;
        }
    }
}

.image-preview-modal {
    backdrop-filter: blur(5px);
    background: rgba($info, 0.2);
}

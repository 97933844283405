// sidebar design
@include respond-above(xl) {
    .WpdSidebar {
        width: 250px;
        min-width: 250px;
        transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
        .WpdSidebarContent {
            width: 250px;
            min-width: 250px;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            background: $sidebar;
            padding: 40px 20px;
            transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
            // displaying
            display: flex;
            flex-direction: column;
            -webkit-box-orient: vertical;
            z-index: 1010;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
                background-image: linear-gradient(
                    to right bottom,
                    rgba($primary, 0.05),
                    rgba($info, 0.05)
                );
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background-image: linear-gradient(
                    to left top,
                    rgba($primary, 0.5),
                    rgba($info, 0.5)
                );
            }
            .WpdSidebarContentTop {
                margin-bottom: 30px;
                .WpdSiteLogo {
                    max-width: 170px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    cursor: pointer;
                    img,
                    svg {
                        display: flex;
                        height: 30px;
                    }
                }
                .WpdSiteLogoCollapsed {
                    max-width: 45px;
                    display: flex;
                    visibility: hidden;
                    opacity: 0;
                    margin-left: 1px;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    cursor: pointer;
                    img,
                    svg {
                        display: none;
                        height: 30px;
                    }
                }
            }
            &Middle {
                flex: 1;
                margin-bottom: 30px;
            }
            .WpdSidebarNav {
                display: flex;
                flex-direction: column;
                height: 100%;
                &Item {
                    &Link {
                        padding: 10px;
                        text-decoration: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 1rem;
                        line-height: 1.3rem;
                        color: $white;
                        border: 1px solid transparent;
                        border-radius: 15px;
                        cursor: pointer;
                        @include transition(all, 0.3s, ease-in-out);
                        &Icon {
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            display: flex;
                            font-size: 14px;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            background: rgba($white, 0.1);
                            border-radius: 10px;
                            color: $white;
                            border: 1px solid rgba(255, 255, 255, 0.05);
                            position: relative;
                            z-index: 1;
                            &:before {
                                @include transition(opacity, 0.2s, ease-in-out);
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                z-index: -1;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                content: '';
                                border-radius: 10px;
                                opacity: 0;
                            }
                        }
                        &Text {
                            margin-left: 15px;
                            color: rgba($white, 0.5);
                            font-weight: 400;
                            min-width: 150px;
                            transition: all 0.1s ease-in-out;
                            transform-origin: left;
                            visibility: visible;
                        }
                        &:hover,
                        &.Active {
                            border-color: rgba($white, 0.05);
                            background: rgba($white, 0.05);
                            .WpdSidebarNavItemLinkIcon {
                                background: transparent;
                                border-color: transparent;
                                &:before {
                                    opacity: 1;
                                }
                            }
                            .WpdSidebarNavItemLinkText {
                                color: $white;
                            }
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
            .WpdSidebarContentBottom {
                .WpdShareButtonWrapper {
                    max-width: 50px;
                    display: flex;
                    justify-content: center;
                    visibility: hidden;
                    opacity: 0;
                    margin-left: 1px;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    .WpdShareButton {
                        width: 30px;
                        min-width: 30px;
                        height: 0;
                        display: none;
                        font-size: 13px;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 10px;
                        color: $white;
                        background: linear-gradient(
                                315deg,
                                $info 0%,
                                $info-lighten 100%
                            ),
                            $primary-lighten;
                        position: relative;
                        z-index: 1;
                        cursor: pointer;
                    }
                }
                .WpdSocialNav {
                    max-width: 180px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    margin-left: 10px;
                    .WpdSocialNavItem {
                        .WpdSocialNavItemLink {
                            cursor: pointer;
                            border-radius: 50px;
                            .WpdSocialNavItemLinkIcon {
                                width: 30px;
                                min-width: 30px;
                                height: 30px;
                                display: flex;
                                font-size: 14px;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 50px;
                                color: $white;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                position: relative;
                                z-index: 1;
                            }
                            &.facebook {
                                .WpdSocialNavItemLinkIcon {
                                    background: #4668b2;
                                }
                            }
                            &.twitter {
                                .WpdSocialNavItemLinkIcon {
                                    background: #2f2f2f;
                                }
                            }
                            &.linkedin {
                                .WpdSocialNavItemLinkIcon {
                                    background: #0076b4;
                                }
                            }
                            &.instagram {
                                .WpdSocialNavItemLinkIcon {
                                    background: #ca3174;
                                }
                            }
                        }
                        &:not(:last-child) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
        &.Toggled {
            width: 93px;
            min-width: 93px;
            .WpdSidebarContent {
                width: 93px;
                min-width: 93px;
                overflow: hidden;
                &Top {
                    .WpdSiteLogo {
                        visibility: hidden;
                        opacity: 0;
                        img,
                        svg {
                            display: none;
                        }
                    }
                    .WpdSiteLogoCollapsed {
                        visibility: visible;
                        opacity: 1;
                        img,
                        svg {
                            display: block;
                        }
                    }
                }
                .WpdSidebarNav {
                    .WpdSidebarNavItem {
                        .WpdSidebarNavItemLink {
                            .WpdSidebarNavItemLinkText {
                                margin-left: 0;
                                transform: rotateY(90deg);
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                    }
                }
                &Bottom {
                    .WpdShareButtonWrapper {
                        visibility: visible;
                        opacity: 1;
                        .WpdShareButton {
                            display: flex;
                            height: 30px;
                        }
                    }
                    .WpdSocialNav {
                        visibility: hidden;
                        opacity: 0;
                        .WpdSocialNavItem {
                            display: none;
                            .WpdSocialNavItemLinkIcon {
                                height: 0;
                            }
                        }
                    }
                }
            }
            .WpdFbCommunity {
                margin-bottom: 0;
                margin-top: 10px;
                &-image {
                    display: none;
                }
                &-title {
                    writing-mode: tb;
                    white-space: break-spaces;
                    height: 130px;
                    transform: rotate(180deg);
                }
                &-info {
                    display: none;
                }
                &-link {
                    padding-right: 4px;
                    margin-bottom: 15px;
                    .WpdText {
                        display: none;
                    }
                }
            }
        }
    }
}
@include respond-between(xs, xl) {
    .WpdSidebar {
        width: 210px;
        min-width: 210px;
        transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
        .WpdSidebarContent {
            width: 210px;
            min-width: 210px;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            background: $sidebar;
            padding: 29px 10px;
            transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
            // displaying
            display: flex;
            flex-direction: column;
            -webkit-box-orient: vertical;
            z-index: 1010;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
                background-image: linear-gradient(
                    to right bottom,
                    rgba($primary, 0.05),
                    rgba($info, 0.05)
                );
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background-image: linear-gradient(
                    to left top,
                    rgba($primary, 0.5),
                    rgba($info, 0.5)
                );
            }
            .WpdSidebarContentTop {
                margin-bottom: 40px;
                .WpdSiteLogo {
                    max-width: 150px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    cursor: pointer;
                    img,
                    svg {
                        display: flex;
                        height: 24px;
                    }
                }
                .WpdSiteLogoCollapsed {
                    max-width: 35px;
                    display: flex;
                    visibility: hidden;
                    opacity: 0;
                    margin-left: 1px;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    cursor: pointer;
                    img,
                    svg {
                        display: none;
                        height: 24px;
                    }
                }
            }
            &Middle {
                flex: 1;
                margin-bottom: 30px;
            }
            .WpdSidebarNav {
                display: flex;
                flex-direction: column;
                height: 100%;
                &Item {
                    &Link {
                        padding: 5px;
                        text-decoration: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 1rem;
                        line-height: 1.3rem;
                        color: $white;
                        border: 1px solid transparent;
                        border-radius: 15px;
                        cursor: pointer;
                        @include transition(all, 0.3s, ease-in-out);
                        &Icon {
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            display: flex;
                            font-size: 14px;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            background: rgba($white, 0.1);
                            border-radius: 10px;
                            color: $white;
                            border: 1px solid rgba(255, 255, 255, 0.05);
                            position: relative;
                            z-index: 1;
                            &:before {
                                @include transition(opacity, 0.2s, ease-in-out);
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                z-index: -1;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                content: '';
                                border-radius: 10px;
                                opacity: 0;
                            }
                        }
                        &Text {
                            margin-left: 15px;
                            color: rgba($white, 0.5);
                            font-weight: 400;
                            min-width: 125px;
                            transition: all 0.1s ease-in-out;
                            transform-origin: left;
                            visibility: visible;
                        }
                        &:hover,
                        &.Active {
                            border-color: rgba($white, 0.05);
                            background: rgba($white, 0.05);
                            .WpdSidebarNavItemLinkIcon {
                                background: transparent;
                                border-color: transparent;
                                &:before {
                                    opacity: 1;
                                }
                            }
                            .WpdSidebarNavItemLinkText {
                                color: $white;
                            }
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
            .WpdSidebarContentBottom {
                .WpdShareButtonWrapper {
                    max-width: 50px;
                    display: flex;
                    justify-content: center;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    .WpdShareButton {
                        width: 30px;
                        min-width: 30px;
                        height: 0;
                        display: none;
                        font-size: 13px;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 10px;
                        color: $white;
                        background: linear-gradient(
                                315deg,
                                $info 0%,
                                $info-lighten 100%
                            ),
                            $primary-lighten;
                        position: relative;
                        z-index: 1;
                        cursor: pointer;
                    }
                }
                .WpdSocialNav {
                    max-width: 180px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    margin-left: 10px;
                    .WpdSocialNavItem {
                        .WpdSocialNavItemLink {
                            cursor: pointer;
                            border-radius: 50px;
                            .WpdSocialNavItemLinkIcon {
                                width: 30px;
                                min-width: 30px;
                                height: 30px;
                                display: flex;
                                font-size: 14px;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 50px;
                                color: $white;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                position: relative;
                                z-index: 1;
                            }
                            &.facebook {
                                .WpdSocialNavItemLinkIcon {
                                    background: #4668b2;
                                }
                            }
                            &.twitter {
                                .WpdSocialNavItemLinkIcon {
                                    background: #1d98e6;
                                }
                            }
                            &.linkedin {
                                .WpdSocialNavItemLinkIcon {
                                    background: #0076b4;
                                }
                            }
                            &.instagram {
                                .WpdSocialNavItemLinkIcon {
                                    background: #ca3174;
                                }
                            }
                        }
                        &:not(:last-child) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
        &:not(.Toggled) {
            width: 62px;
            min-width: 62px;
            .WpdSidebarContent {
                width: 62px;
                min-width: 62px;
                overflow: hidden;
                &Top {
                    .WpdSiteLogo {
                        visibility: hidden;
                        opacity: 0;
                        img,
                        svg {
                            display: none;
                        }
                    }
                    .WpdSiteLogoCollapsed {
                        visibility: visible;
                        opacity: 1;
                        img,
                        svg {
                            display: block;
                        }
                    }
                }
                .WpdSidebarNav {
                    .WpdSidebarNavItem {
                        .WpdSidebarNavItemLink {
                            .WpdSidebarNavItemLinkText {
                                margin-left: 0;
                                transform: rotateY(90deg);
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                    }
                }
                &Bottom {
                    .WpdShareButtonWrapper {
                        visibility: visible;
                        opacity: 1;
                        .WpdShareButton {
                            display: flex;
                            height: 30px;
                        }
                    }
                    .WpdSocialNav {
                        visibility: hidden;
                        opacity: 0;
                        .WpdSocialNavItem {
                            display: none;
                            .WpdSocialNavItemLinkIcon {
                                height: 0;
                            }
                        }
                    }
                }
            }
            .WpdFbCommunity {
                margin-bottom: 0;
                margin-top: 10px;
                &-image {
                    display: none;
                }
                &-title {
                    writing-mode: tb;
                    white-space: break-spaces;
                    height: 130px;
                    transform: rotate(180deg);
                }
                &-info {
                    display: none;
                }
                &-link {
                    padding-right: 4px;
                    margin-bottom: 15px;
                    .WpdText {
                        display: none;
                    }
                }
            }
        }
    }
}
@include respond-below(xs) {
    .WpdSidebar {
        width: 210px;
        min-width: 210px;
        transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
        .WpdSidebarContent {
            width: 210px;
            min-width: 210px;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            background: $sidebar;
            padding: 29px 10px;
            transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
            // displaying
            display: flex;
            flex-direction: column;
            -webkit-box-orient: vertical;
            z-index: 1010;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
                background-image: linear-gradient(
                    to right bottom,
                    rgba($primary, 0.05),
                    rgba($info, 0.05)
                );
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background-image: linear-gradient(
                    to left top,
                    rgba($primary, 0.5),
                    rgba($info, 0.5)
                );
            }
            .WpdSidebarContentTop {
                margin-bottom: 40px;
                .WpdSiteLogo {
                    max-width: 150px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    cursor: pointer;
                    img,
                    svg {
                        display: flex;
                        height: 24px;
                    }
                }
                .WpdSiteLogoCollapsed {
                    max-width: 35px;
                    display: flex;
                    visibility: hidden;
                    opacity: 0;
                    margin-left: 1px;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    cursor: pointer;
                    img,
                    svg {
                        display: none;
                        height: 24px;
                    }
                }
            }
            &Middle {
                flex: 1;
                margin-bottom: 30px;
            }
            .WpdSidebarNav {
                display: flex;
                flex-direction: column;
                height: 100%;
                &Item {
                    &Link {
                        padding: 5px;
                        text-decoration: none;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 1rem;
                        line-height: 1.3rem;
                        color: $white;
                        border: 1px solid transparent;
                        border-radius: 15px;
                        cursor: pointer;
                        @include transition(all, 0.3s, ease-in-out);
                        &Icon {
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            display: flex;
                            font-size: 14px;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            background: rgba($white, 0.1);
                            border-radius: 10px;
                            color: $white;
                            border: 1px solid rgba(255, 255, 255, 0.05);
                            position: relative;
                            z-index: 1;
                            &:before {
                                @include transition(opacity, 0.2s, ease-in-out);
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                z-index: -1;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                content: '';
                                border-radius: 10px;
                                opacity: 0;
                            }
                        }
                        &Text {
                            margin-left: 15px;
                            color: rgba($white, 0.5);
                            font-weight: 400;
                            min-width: 125px;
                            transition: all 0.1s ease-in-out;
                            transform-origin: left;
                            visibility: visible;
                        }
                        &:hover,
                        &.Active {
                            border-color: rgba($white, 0.05);
                            background: rgba($white, 0.05);
                            .WpdSidebarNavItemLinkIcon {
                                background: transparent;
                                border-color: transparent;
                                &:before {
                                    opacity: 1;
                                }
                            }
                            .WpdSidebarNavItemLinkText {
                                color: $white;
                            }
                        }
                    }
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
            .WpdSidebarContentBottom {
                .WpdShareButtonWrapper {
                    max-width: 50px;
                    display: flex;
                    justify-content: center;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1);
                    .WpdShareButton {
                        width: 30px;
                        min-width: 30px;
                        height: 0;
                        display: none;
                        font-size: 13px;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-radius: 10px;
                        color: $white;
                        background: linear-gradient(
                                315deg,
                                $info 0%,
                                $info-lighten 100%
                            ),
                            $primary-lighten;
                        position: relative;
                        z-index: 1;
                        cursor: pointer;
                    }
                }
                .WpdSocialNav {
                    max-width: 180px;
                    display: flex;
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
                    margin-left: 10px;
                    .WpdSocialNavItem {
                        .WpdSocialNavItemLink {
                            cursor: pointer;
                            border-radius: 50px;
                            .WpdSocialNavItemLinkIcon {
                                width: 30px;
                                min-width: 30px;
                                height: 30px;
                                display: flex;
                                font-size: 14px;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                border-radius: 50px;
                                color: $white;
                                background: linear-gradient(
                                        315deg,
                                        $info 0%,
                                        $info-lighten 100%
                                    ),
                                    $primary-lighten;
                                position: relative;
                                z-index: 1;
                            }
                            &.facebook {
                                .WpdSocialNavItemLinkIcon {
                                    background: #4668b2;
                                }
                            }
                            &.twitter {
                                .WpdSocialNavItemLinkIcon {
                                    background: #1d98e6;
                                }
                            }
                            &.linkedin {
                                .WpdSocialNavItemLinkIcon {
                                    background: #0076b4;
                                }
                            }
                            &.instagram {
                                .WpdSocialNavItemLinkIcon {
                                    background: #ca3174;
                                }
                            }
                        }
                        &:not(:last-child) {
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
        &:not(.Toggled) {
            width: 0;
            min-width: 0;
            .WpdSidebarContent {
                left: -210px;
            }
        }
    }
}

// secondary sidebar
.WpdTab {
    background: $white;
    z-index: 500;
    margin-bottom: 30px;
    width: 100%;
    min-width: 100%;
    padding: 0;
    @include respond-below(md) {
        margin-bottom: 20px;
    }
    .WpdTabNav {
        display: flex;
        flex-wrap: wrap;
        .WpdTabNavItem {
            flex-grow: 1;
            border: 1px solid darken($light, 1%);
            .WpdTabNavItemLink {
                text-decoration: none;
                display: flex;
                align-items: center;
                font-size: 1rem;
                line-height: 1.3rem;
                color: $secondary;
                width: 100%;
                justify-content: center;
                padding: 12px 40px;
                &:hover,
                &.Active {
                    color: $dark;
                }
                &.Active {
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(
                            270deg,
                            $info 0%,
                            $info-lighten 100%
                        );
                    }
                }
            }
        }
    }
}
.WpdFbCommunity {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 -20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include respond-below(xs) {
        margin: 0 -10px;
        margin-bottom: 20px;
    }
    &-image {
        width: 100%;
        margin-bottom: -30%;
    }
    &-title {
        font-size: 18px;
        color: $white;
        margin-bottom: 5px;
        font-weight: 600;
        letter-spacing: -0.025em;
        @include respond-below(xs) {
            font-size: 16px;
        }
    }
    &-info {
        font-size: 14px;
        color: $secondary;
        letter-spacing: -1px;
        margin-bottom: 10px;
        @include respond-below(xs) {
            font-size: 13px;
        }
    }
    &-link {
        background: $white;
        border-radius: 20px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        padding: 4px;
        padding-right: 10px;
        font-size: 13px;
        font-weight: 500;
        color: #5633d1;
        cursor: pointer;
        letter-spacing: -0.025em;
        .WpdIcon {
            height: 22px;
            width: 22px;
            min-width: 22px;
            background: #5633d1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $white;
            font-size: 12px;
        }
        .WpdText {
            margin-left: 6px;
        }
    }
}

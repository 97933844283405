@import 'variables';
@import 'mixins/media';
@import 'reboot';
@import 'functions';
@import 'mixins/transition';
@import 'utilities/background&color';
@import 'utilities/button';
@import 'utilities/dropdown';
@import 'utilities/utilities';
@import 'utilities/form';
@import 'utilities/pagination';
@import 'utilities/login';
@import 'utilities/emptycart';
@import 'utilities/modal';
@import 'utilities/conversations';

//------------------------
//////body structure//////
//------------------------
.WpdPageBody {
    display: flex;
    overflow-x: hidden;
    @import 'utilities/navbar';
    @import 'utilities/stickyHeader';
    .WpdContentArea {
        display: flex;
        flex-direction: column;
        background: $light;
        min-height: 100vh;
        flex-grow: 1;
        @import 'utilities/header';
        @import 'utilities/footer';
        @include respond-above(xl) {
            min-width: calc(100% - 250px);
        }
        @include respond-below(xl) {
            min-width: calc(100% - 62px);
        }
        @include respond-below(xs) {
            min-width: calc(100%);
        }
        .WpdContentAreaBody {
            flex: 1;
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            @include respond-below(xxl) {
                padding: 0 30px;
            }
            @include respond-below(sm) {
                padding: 0 15px;
            }
            &:last-child {
                padding-bottom: 50px;
            }
            .WpdTabWrapper {
                display: flex;
                height: 100%;
                flex-direction: column;
                .WpdTabContent {
                    flex-grow: 1;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    width: 100%;
                }
            }
        }
        @import 'utilities/checkout';
    }
}

//-------------------------
///////Every Section///////
//-------------------------
.WpdMessageSection {
    display: flex;
    .WpdMessageSectionLeft {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .WpdMessageSectionTitle {
            font-size: 2.5rem;
            color: $primary;
            font-weight: 500;
            margin-bottom: 20px;
            line-height: 1;
            @include respond-below(hl) {
                font-size: 2.25rem;
            }
            @include respond-below(xxl) {
                font-size: 2rem;
            }
            @include respond-below(md) {
                font-size: 1.5rem;
            }
        }
        .WpdSectionTitle {
            margin-bottom: 20px;
            @include respond-below(lg) {
                margin-bottom: 15px;
            }
        }
        .WpdMessageSectionMessage {
            font-size: 1rem;
            color: $secondary;
            font-weight: 400;
            line-height: 1.8;
            max-width: 800px;
            @include respond-below(lg) {
                font-size: 0.875rem;
            }
        }
        .WpdButtonGroup {
            margin-top: 30px;
            @include respond-below(md) {
                flex-wrap: wrap;
                margin-top: 10px;
            }
        }
    }
    .WpdMessageSectionRight {
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        @include respond-below(sm) {
            display: none;
        }
        img,
        svg,
        object {
            width: 250px;
            margin-top: -40px;
            margin-bottom: -50px;
            @include respond-below(xxl) {
                margin-top: -25px;
                margin-bottom: -30px;
                width: 200px;
                align-self: center;
            }
            @include respond-below(md) {
                width: 80px;
            }
        }
    }
    &.WpdKnowledgebaseMessage {
        .WpdMessageSectionRight {
            img,
            svg,
            object {
                margin-top: -30px;
                margin-bottom: -35px;
                @include respond-below(xl) {
                    margin-top: -20px;
                    margin-bottom: -25px;
                }
            }
        }
    }
}

.WpdMyPostGridSection {
    display: flex;
    flex-direction: column;
}

.WpdDataTableWrapper {
    .WpdDataTable {
        tbody {
            tr {
                td {
                    &.WpdTableProductInfoColumn {
                        width: 45%;
                        @include respond-below(lg) {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                        .WpdTableProductInfoWrapper {
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            @include respond-below(sm) {
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                            }
                            .WpdTableProductInfo {
                                display: flex;
                                align-items: center;
                                margin-right: 20px;
                                @include respond-below(hl) {
                                    margin-right: 10px;
                                }
                                .WpdTableProductThumbnail {
                                    width: 70px;
                                    height: 70px;
                                    min-width: 70px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    @include respond-below(hl) {
                                        height: 60px;
                                        width: 60px;
                                        min-width: 60px;
                                    }
                                    @include respond-below(xxl) {
                                        width: 50px;
                                        height: 50px;
                                        min-width: 50px;
                                    }
                                    @include respond-below(sm) {
                                        width: 40px;
                                        height: 40px;
                                        min-width: 40px;
                                    }
                                    img {
                                        display: flex;
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                    .WpdProductTextIcon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 50%;
                                        width: 100%;
                                        height: 100%;
                                        font-size: 1.5rem;
                                        font-weight: 700;
                                        text-align: center;
                                        background: linear-gradient(
                                            265deg,
                                            $info 0%,
                                            $info-lighten 100%
                                        );
                                        color: set-text-color($info);
                                        @include respond-below(hl) {
                                            font-size: 1.25rem;
                                        }
                                        @include respond-below(xxl) {
                                            font-size: 1.125rem;
                                        }
                                    }
                                }
                                .WpdTableProductDetails {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    margin-left: 20px;
                                    @include respond-below(hl) {
                                        margin-left: 15px;
                                    }
                                    @include respond-below(xxl) {
                                        margin-left: 10px;
                                    }
                                    @include respond-below(sm) {
                                        margin-left: 8px;
                                    }
                                    .WpdTableProductTitle {
                                        font-size: 1.25rem;
                                        line-height: 1.1;
                                        font-weight: 500;
                                        color: $dark;
                                        margin-top: 5px;
                                        @include respond-below(hl) {
                                            font-size: 1.125rem;
                                        }
                                        @include respond-below(xxl) {
                                            font-size: 1.0625rem;
                                        }
                                    }
                                    .WpdTableProductSemiTitle {
                                        font-size: 0.875rem;
                                        font-weight: 500;
                                        color: $secondary;
                                        margin-bottom: 5px;
                                        @include respond-below(xxl) {
                                            font-size: 0.75rem;
                                        }
                                        span {
                                            &:not(:last-child) {
                                                margin-right: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.WpdProductMetaColumn {
                        @include respond-below(lg) {
                            flex: 0 0 100%;
                            max-width: 100%;
                            padding-bottom: 15px;
                        }
                        .WpdProductMetaWrapper {
                            display: flex;
                            flex-direction: column;
                            white-space: nowrap;
                            @include respond-below(lg) {
                                flex: 0 0 100%;
                                max-width: 100%;
                                flex-direction: row;
                                flex-wrap: wrap;
                            }
                            .WpdProductMeta {
                                display: flex;
                                @include respond-below(lg) {
                                    flex-grow: 1;
                                    margin-bottom: 5px;
                                    padding-right: 10px;
                                    display: flex;
                                    align-self: center;
                                }
                                &Label {
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    color: $secondary;
                                    min-width: 100px;
                                    @include respond-below(hl) {
                                        min-width: 80px;
                                    }
                                    @include respond-below(lg) {
                                        min-width: initial;
                                        margin-right: 10px;
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                                &Info {
                                    color: $dark;
                                }
                                @include respond-above(lg) {
                                    &:not(:last-child) {
                                        margin-bottom: 15px;
                                    }
                                }
                            }
                        }
                    }
                    &.WpdProductActionColumn {
                        width: 350px;
                        max-width: 400px;
                        .WpdProductActionWrapper {
                            min-width: 280px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            margin-bottom: -15px;
                            white-space: nowrap;
                            @include respond-below(lg) {
                                min-width: 100%;
                                margin-bottom: -10px;
                            }
                            .WpdProductCode {
                                flex-basis: 100%;
                                margin-bottom: 15px;
                                @include respond-below(lg) {
                                    min-width: 100%;
                                    margin-bottom: 10px;
                                }
                                .WpdButtonInner {
                                    justify-content: space-between;
                                    @include respond-below(sm) {
                                        .WpdText {
                                            width: 180px;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                        }
                                    }
                                }
                            }
                            .WpdManageSite,
                            .WpdViewUpgrades {
                                flex-basis: calc(50% - 10px);
                                margin-bottom: 15px;
                                @include respond-below(lg) {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                    .WpdActionWrapper {
                        display: inline-flex;
                        white-space: nowrap;
                        > * {
                            &:not(:last-child) {
                                margin-right: 20px;
                                @include respond-below(hl) {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                    &.WpdActiveStatus {
                        @include respond-below(lg) {
                            width: 90px;
                        }
                    }
                    &.WpdPaymentLabel {
                        @include respond-below(lg) {
                            flex: 0 0 200px;
                        }
                    }
                }
            }
        }
    }
}

.WpdMyAffiliateSection {
    .WpdAffiliateCreativesThumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: 250px;
        svg,
        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
        }
    }
    .WpdAffiliateCreativeWrapper {
        margin-bottom: 30px;
        .WpdAffiliateCreativeItem {
            padding: 20px;
            border-radius: 5px;
            background: #fff;
            .WpdAffiliateTitle {
                margin: -20px -20px 0;
                padding: 15px 20px;
                border-bottom: 1px solid $light;
            }
            .WpdPreText {
                position: relative;
                .wpd-icon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    color: $info;
                    cursor: pointer;
                    padding: 2px;
                }
            }
        }
    }
}

.WpdSectionPanel {
    .WpdProductImage {
        width: 150px;
        img {
            max-height: 100%;
            max-width: 100%;
            width: 100%;
        }
        @include respond-below(md) {
            display: none;
        }
    }
}

.is-loading {
    position: relative;
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        opacity: 0.6;
        z-index: 11;
    }
    &:after {
        font-family: 'Wpd-Icon' !important;
        font-weight: 900;
        content: '\e91c';
        height: 50px;
        width: 50px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        font-size: 2rem;
        color: $info;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
        transform-origin: center center;
        @keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        -webkit-animation: spin 1s infinite steps(8);
        animation: spin 1s infinite steps(8);
    }
}
.is-updating {
    position: relative;
    > * {
        opacity: 0.4;
        filter: blur(0.2px);
    }
    &:after {
        font-family: 'Wpd-Icon' !important;
        font-weight: 900;
        content: '\e91c';
        height: 50px;
        width: 50px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        font-size: 2rem;
        color: $info;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
        transform-origin: center center;
        @keyframes spin {
            0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        -webkit-animation: spin 1s infinite steps(8);
        animation: spin 1s infinite steps(8);
    }
}
.is-loading-modal {
    .modal-content {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: #fff;
            opacity: 0.6;
            z-index: 11;
        }
        &:after {
            font-family: 'Wpd-Icon' !important;
            font-weight: 900;
            content: '\e91c';
            height: 50px;
            width: 50px;
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
            font-size: 2rem;
            color: $info;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 11;
            @keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
            -webkit-animation: spin 1s infinite steps(8);
            animation: spin 1s infinite steps(8);
        }
    }
}
.is-loading-tr {
    opacity: 0.7;
}
button.WpdDisabled {
    cursor: not-allowed;
}

.WpdBillingMethodWrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}
.WpdBillingMethod {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 20px 30px;
    background: $white;
    border-radius: 5px;
    width: 430px;
    max-width: 100%;
    .WpdController {
        position: absolute;
        right: 30px;
        top: 30px;
        display: flex;
        gap: 20px;
    }
    .WpdIcon {
        height: 80px;
        width: 80px;
        flex-shrink: 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        object-fit: contain;
    }
    .WpdLabel {
        font-size: 14px;
        color: $secondary;
        line-height: 1.1;
        margin-bottom: 5px;
    }
    .WpdNumberWrapper {
        display: flex;
        align-items: center;
        gap: 7px;
        .WpdDotts {
            height: 15px;
            width: 60px;
            background-image: radial-gradient(
                lighten($checkout-border-color, 5%) 40%,
                transparent 40%
            );
            background-position:
                0 0,
                50px 50px;
            background-size: 15px 15px;
        }
        .WpdNumber {
            display: flex;
            font-size: 20px;
            line-height: 1.3;
            color: $dark;
        }
    }
}

.WpdAddBillingMethod {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    background: $white;
    border-radius: 5px;
    width: 430px;
    max-width: 100%;
    min-height: 150px;
    margin-top: 30px;
    border: 2px dashed lighten($checkout-border-color, 5%);
    @include transition();
    .WpdIcon {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: $checkout-border-color;
        border: 4px solid $checkout-border-color;
        margin-right: 20px;
    }
    .WpdText {
        font-size: 22px;
        color: $secondary;
        font-weight: 500;
    }
    &:hover {
        background: rgba($primary, 0.025);
    }
}
.WpdShopManager tr td .WpdOrderList {
    margin-bottom: 10px;
    list-style: disc;
    display: list-item;
    margin-left: 10px;
}
.WpdShopManager .WpdSearch {
    position: relative;
    input {
        padding-right: 28px !important;
    }
}
.WpdShopManager .WpdSearchClear {
    position: absolute;
    right: 50px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    font-size: 12px;
    top: 12px;
    color: #fff;
}
.WpdHas2fa {
    position: relative;
    z-index: 1;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        opacity: 0.7;
        z-index: 99;
    }
}

// Button design
@mixin button-emphasis-variant($parent, $colorName, $color) {
    #{$parent} {
        .WpdButtonInner {
            background: $white;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color);
            .WpdIcon {
                background: linear-gradient(
                    270deg,
                    rgba($color, 0.15) 0%,
                    rgba(map-get($theme-colors-lighten, $colorName), 0.15) 100%
                );
                color: $color;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, filter);
            }
            .WpdText {
                background: linear-gradient(
                    270deg,
                    $color 0%,
                    map-get($theme-colors-lighten, $colorName) 100%
                );
                color: set-text-color($color);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color);
            }
        }
        @if ($color == white) {
            .WpdButtonInner {
                background: transparent;
            }
            &:before {
                background: transparent !important;
                border: 1px solid $color !important;
                opacity: 1 !important;
            }
            &:hover {
                &:before {
                    background: $color !important;
                }
            }
        }
        &:before {
            background: linear-gradient(
                315deg,
                $color 0%,
                map-get($theme-colors-lighten, $colorName) 100%
            );
            opacity: 0.3;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
        }
        &.WpdFilled {
            .WpdButtonInner {
                background: transparent;
                .WpdIcon {
                    background: set-text-color($color);
                }
                .WpdText {
                    background: transparent;
                    color: set-text-color($color);
                    background-clip: initial;
                    -webkit-background-clip: initial;
                    -webkit-text-fill-color: initial;
                }
            }
            &:before {
                opacity: 1;
            }
            &:not([class*='WpdHover']) {
                &:hover {
                    .WpdIcon {
                        filter: brightness(105%);
                    }
                    &:before {
                        filter: brightness(105%);
                    }
                }
            }
            &.WpdRemoveButton {
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, border);
                .WpdRemoveIcon {
                    border-color: $color;
                    background: $color;
                    color: set-text-color($color);
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                }
                .WpdRemoveText {
                    color: $color;
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                }
            }
        }
        &.WpdLabelButton,
        &.WpdSmallButton {
            .WpdButtonInner {
                .WpdIcon {
                    background: transparent;
                    color: $color;
                }
            }
            &.WpdFilled {
                .WpdButtonInner {
                    .WpdIcon {
                        background: transparent;
                        color: set-text-color($color);
                    }
                }
            }
        }
        &.WpdIconButton:not(.WpdOnlyIcon) {
            background: $color;
            color: set-text-color($color);
        }
        &.WpdIconButton.WpdOnlyIcon {
            background: transparent;
            color: $color;
        }
        &.WpdRemoveButton {
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, border);
            .WpdRemoveIcon {
                border-color: $color;
                color: $color;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, border);
            }
            .WpdRemoveText {
                color: $color;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, border);
            }
        }
    }
    a#{$parent},
    button#{$parent} {
        &:not([class*='WpdHover']) {
            &.Active,
            &:hover {
                .WpdButtonInner {
                    background: transparent;
                    .WpdIcon {
                        background: set-text-color($color);
                    }
                    .WpdText {
                        background: transparent;
                        color: set-text-color($color);
                        background-clip: initial;
                        -webkit-background-clip: initial;
                        -webkit-text-fill-color: initial;
                    }
                }
                &:before {
                    opacity: 1;
                }
                &.WpdLabelButton,
                &.WpdSmallButton {
                    .WpdButtonInner {
                        .WpdIcon {
                            background: transparent;
                            color: set-text-color($color);
                        }
                    }
                }
            }
        }
    }
}

// alert design
@mixin alert-emphasis-variant($parent, $colorName, $color) {
    #{$parent} {
        .WpdButtonInner {
            @if ($color == $primary) {
                background: lighten($color, 75%);
            } @else if ($color == $success) {
                background: lighten($color, 53%);
            } @else if ($color == $warning) {
                background: lighten($color, 38%);
            } @else if ($color == $danger) {
                background: lighten($color, 52%);
            } @else {
                background: lighten($color, 55%);
            }
            @include transition($duration: 0.2s);
            @include transition-properties(background, color);
            .WpdIcon {
                background: linear-gradient(
                    270deg,
                    rgba($color, 0.15) 0%,
                    rgba(map-get($theme-colors-lighten, $colorName), 0.15) 100%
                );
                color: $color;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, filter);
            }
            .WpdText {
                background: linear-gradient(
                    270deg,
                    $color 0%,
                    map-get($theme-colors-lighten, $colorName) 100%
                );
                color: set-text-color($color);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include transition($duration: 0.2s);
                @include transition-properties(background, color);
            }
        }
        &:before {
            background: linear-gradient(
                315deg,
                $color 0%,
                map-get($theme-colors-lighten, $colorName) 100%
            );
            opacity: 0.3;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
        }
        &.WpdFilled {
            .WpdButtonInner {
                background: transparent;
                .WpdIcon {
                    background: set-text-color($color);
                }
                .WpdText {
                    background: transparent;
                    color: set-text-color($color);
                    background-clip: initial;
                    -webkit-background-clip: initial;
                    -webkit-text-fill-color: initial;
                }
            }
            &:before {
                opacity: 1;
            }
            &:not([class*='WpdHover']) {
                &:hover {
                    .WpdIcon {
                        filter: brightness(105%);
                    }
                    &:before {
                        filter: brightness(105%);
                    }
                }
            }
        }
        &.WpdLabelButton,
        &.WpdSmallButton {
            .WpdButtonInner {
                .WpdIcon {
                    background: transparent;
                    color: $color;
                }
            }
            &.WpdFilled {
                .WpdButtonInner {
                    .WpdIcon {
                        background: transparent;
                        color: set-text-color($color);
                    }
                }
            }
        }
    }
    a#{$parent},
    button#{$parent} {
        &:not([class*='WpdHover']) {
            &.Active,
            &:hover {
                .WpdButtonInner {
                    background: transparent;
                    .WpdIcon {
                        background: set-text-color($color);
                    }
                    .WpdText {
                        background: transparent;
                        color: set-text-color($color);
                        background-clip: initial;
                        -webkit-background-clip: initial;
                        -webkit-text-fill-color: initial;
                    }
                }
                &:before {
                    opacity: 1;
                }
                &.WpdLabelButton,
                &.WpdSmallButton {
                    .WpdButtonInner {
                        .WpdIcon {
                            background: transparent;
                            color: set-text-color($color);
                        }
                    }
                }
            }
        }
    }
}

// hover design
@mixin hover-emphasis-variant($parent, $colorName, $color) {
    #{$parent} {
        &.WpdFilled {
            &:hover {
                .WpdIcon {
                    filter: brightness(105%);
                }
                &:before {
                    filter: brightness(105%);
                }
                &.WpdRemoveButton {
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                    .WpdRemoveIcon {
                        border-color: $color;
                        background: $color;
                        color: set-text-color($color);
                        @include transition($duration: 0.2s);
                        @include transition-properties(
                            background,
                            color,
                            border
                        );
                    }
                    .WpdRemoveText {
                        color: $color;
                        @include transition($duration: 0.2s);
                        @include transition-properties(
                            background,
                            color,
                            border
                        );
                    }
                }
            }
        }
        &.WpdDisabled {
            .WpdButtonInner .WpdText {
                color: $secondary;
            }
        }
    }
    label#{$parent},
    a#{$parent},
    button#{$parent} {
        &.Active,
        &:hover {
            .WpdButtonInner {
                background: transparent;
                .WpdIcon {
                    background: set-text-color($color);
                }
                .WpdText {
                    background: transparent;
                    color: set-text-color($color);
                    background-clip: initial;
                    -webkit-background-clip: initial;
                    -webkit-text-fill-color: initial;
                }
            }
            &:before {
                background: linear-gradient(
                    315deg,
                    $color 0%,
                    map-get($theme-colors-lighten, $colorName) 100%
                );
                opacity: 1;
            }
            &.WpdLabelButton,
            &.WpdSmallButton {
                .WpdButtonInner {
                    .WpdIcon {
                        background: transparent;
                        color: set-text-color($color);
                    }
                }
            }
            &.WpdRemoveButton {
                @include transition($duration: 0.2s);
                @include transition-properties(background, color, border);
                .WpdRemoveIcon {
                    border-color: lighten($color, 5%);
                    background: lighten($color, 5%);
                    color: set-text-color($color);
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                }
                .WpdRemoveText {
                    color: lighten($color, 5%);
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                }
            }
        }
    }
}

.loaderDiv {
    display: flex;
    height: 100%;
    position: relative;
    min-height: 300px;
    min-height: 35vh;
    align-items: center;
    justify-content: center;
    background: #fff;
}

// content color
@mixin color-emphasis-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }
    a#{$parent} {
        &:hover,
        &:focus {
            @if $color != #000000 {
                color: darken($color, 10%) !important;
                box-shadow: none !important;
            }
        }
    }
} // content color
@mixin hover-color-emphasis-variant($parent, $color) {
    #{$parent} {
        @include transition($for: color);
        &:hover {
            color: $color !important;
        }
    }
    a#{$parent} {
        &:hover,
        &:focus {
            @if $color != #000000 {
                color: darken($color, 10%) !important;
                box-shadow: none !important;
            }
        }
    }
}

// content background
@mixin background-emphasis-variant($parent, $color) {
    #{$parent} {
        background-color: $color !important;
    }
}

// gradient background
@mixin gradient-emphasis-variant($parent, $colorName, $color) {
    #{$parent} {
        background-image: linear-gradient(
            110deg,
            $color 0%,
            map-get($theme-colors-lighten, $colorName) 100%
        );
        color: set-text-color($color);
    }
}

// A map of breakpoints.
$breakpoints: (
    xs: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1401px,
    hl: 1601px,
    ml: 1920px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }
        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a error.
        @error 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);
        // Write the media query.
        @media (max-width: ($breakpoint-value - 0.02)) {
            @content;
        }
        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a error.
        @error 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
    {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);
        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 0.02)) {
            @content;
        }
        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower) == false) {
            // Log a error.
            @error 'Your lower breakpoint was invalid: #{$lower}.';
        }
        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper) == false) {
            // Log a error.
            @error 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

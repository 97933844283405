// section gap
.WpdSectionGap {
    margin-top: 50px;
    @include respond-below(xxl) {
        margin-top: 30px;
    }
    @include respond-below(md) {
        margin-top: 20px;
    }
}
.WpdPaginationWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
}
// col gap
.WpdHasColGap {
    margin-bottom: -30px;
    > .col-md-1,
    > .col-md-2,
    > .col-md-3,
    > .col-md-4,
    > .col-md-5,
    > .col-md-6,
    > .col-md-7,
    > .col-md-8,
    > .col-md-9,
    > .col-md-10,
    > .col-md-11,
    > .col-md-12,
    > .col {
        margin-bottom: 30px !important;
    }
}

.WpdLink {
    color: $info;
    text-decoration: underline;
    font-style: italic;
    &:hover {
        text-decoration: underline;
        color: darken($info, 10%);
    }
}

// page wrapper
.WpPageBody {
    display: flex;
}

// section title
.WpdSectionTitleWrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    @include respond-below(xxl) {
        margin-bottom: 20px;
    }
    @include respond-below(md) {
        margin-bottom: 10px;
    }
}
.WpdSectionTitle {
    font-weight: 500;
    line-height: 1.3;
    font-size: 1.875rem;
    color: $primary;
    @include respond-below(hl) {
        font-size: 1.75rem;
    }
    @include respond-below(hl) {
        font-size: 1.625rem;
    }
}
.WpdSectionSemiTitle {
    font-weight: 500;
    line-height: 1.3;
    font-size: 1.375rem;
    color: $primary;
    @include respond-below(md) {
        font-size: 1.125rem;
    }
}
.Wpd2FAMethodSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .Wpd2FAMethodHeading {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.2;
    }
    .Wpd2FAMethodWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .Wpd2FAMethod {
            display: flex;
            align-items: center;
            padding: 24px;
            gap: 16px;
            background-color: #ffffff;
            border-radius: 4px;
            .Wpd2FAMethodIcon {
                height: 48px;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: #f5fcff;
                border: 1px solid #ecf9ff;
                border-radius: 6px;
                font-size: 36px;
                color: #0cb1f9;
            }
            .Wpd2FAMethodContent {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 10px;
                .Wpd2FAMethodTitleWrapper {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    row-gap: 4px;
                    flex-wrap: wrap-reverse;
                    .Wpd2FAMethodTitle {
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.2;
                        color: #25396f;
                    }
                    .Wpd2FAMethodBadge {
                        white-space: nowrap;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.2;
                        padding: 2px 8px;
                        min-height: 20px;
                        border-radius: 50px;
                        border: 1px solid;
                        &.Success {
                            color: #2f9b00;
                            border-color: #2f9b00;
                            background-color: #f0ffea;
                        }
                    }
                }
                .Wpd2FAMethodInfo {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.2;
                    color: #7c8db5;
                }
            }
            .Wpd2FAMethodDropdownButton {
                height: 24px;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                font-size: 24px;
                color: #25396f;
                line-height: 1;
                cursor: pointer;
            }
        }
    }
}

// applicant empty message
.WpdEmptyMessage {
    padding: 30px 26.5px;
    font-size: 1.125rem;
    line-height: 1.5;
    background: #fff;
    border-radius: 10px;
    color: #ff5f74;
    font-weight: 500;
    &:not(first-child) {
        margin-top: 30px;
    }
    &:not(last-child) {
        margin-bottom: 30px;
    }
}

// Error Message
.WpdNoDataFound {
    padding: 20px;
    background: #fff;
    color: $warning;
    display: flex;
    align-items: center;
    width: 100%;
    .WpdIcon {
        height: 50px;
        width: 50px;
        min-width: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $warning;
        background: rgba($warning, 0.05);
        border-radius: 100%;
        font-size: 1.25rem;
    }
    .WpdText {
        font-size: 1.125rem;
        line-height: 1.25;
        &:not(:first-child) {
            margin-left: 20px;
        }
    }
    &:not(first-child) {
        margin-top: 30px;
    }
    &:not(last-child) {
        margin-bottom: 30px;
    }
}

.WpdButtonGroup {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    @include respond-below(md) {
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: -5px;
    }
    .WpdButton,
    .WpdSmallButton,
    .WpdLabelButton {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        @include respond-below(md) {
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
}

// panel design
.WpdSectionPanel {
    padding: 50px;
    background: $white;
    position: relative;
    display: inline-block;
    width: 100%;
    @include respond-below(lg) {
        padding: 30px;
    }
    @include respond-below(md) {
        padding: 20px;
    }
    .WpdPanelTitle {
        display: flex;
        align-items: center;
    }
    .WpdPanelItem {
        transition: all 0.5s linear;
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
    .WpdPanelToggler {
        background: $white;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;
        font-size: 0.75rem;
        cursor: pointer;
        .WpdIcon {
            transform: rotateX(180deg);
            display: flex;
        }
    }
    &.WpdPanelCollapsible {
        margin-bottom: 30px;
    }
    &.WpdCollapsed {
        .WpdPanelToggler {
            .WpdIcon {
                transform: rotateX(0deg);
            }
        }
    }
    &.WpdDisable {
        .WpdPanelToggler {
            cursor: no-drop;
        }
    }
}

// Data Table
.WpdDataTableWrapper {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin-top: -10px;
    margin-bottom: -10px;
    .WpdDataTable {
        width: 100%;
        display: table;
        border-collapse: separate !important;
        border-spacing: 0 10px;
        thead {
            tr {
                height: 70px;
                background: $white;
                @include respond-below(xxl) {
                    height: 50px;
                }
                th {
                    font-weight: 600;
                    font-size: 1rem;
                    color: $dark;
                    line-height: 1.1;
                    padding: 20px;
                    @include respond-below(hl) {
                        padding: 15px;
                    }
                    @include respond-below(xl) {
                        padding: 10px;
                    }
                }
            }
        }
        tbody {
            tr {
                background: $white;
                td {
                    > label {
                        font-size: 0.875rem;
                        font-weight: 500;
                        margin-bottom: 5px;
                        color: $secondary;
                        display: flex;
                        width: 100%;
                        line-height: 1;
                        @include respond-above(lg) {
                            display: none;
                        }
                    }
                    padding: 20px;
                    color: $dark;
                    border-left: 1px solid transparent;
                    border-right: 1px solid transparent;
                    font-weight: 400;
                    font-size: 0.875rem;
                    @include respond-below(hl) {
                        padding: 15px;
                    }
                    @include respond-below(xl) {
                        padding: 10px;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid darken($light, 2%);
                    }
                }
            }
        }
    }
    @include respond-below(lg) {
        display: block;
        width: 100%;
        overflow: hidden;
        margin-top: 0px;
        margin-bottom: 0px;
        .WpdDataTable {
            width: 100%;
            display: flex;
            flex-direction: column;
            thead {
                display: none;
            }
            tbody {
                display: flex;
                flex-direction: column;
                tr {
                    display: flex;
                    flex-wrap: wrap;
                    td {
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        border: 1px solid $light;
                        flex-grow: 1;
                    }
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

// post grid
.WpdPostGridWrapper {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    @include respond-below(xxl) {
        grid-gap: 30px;
    }
    @include respond-below(lg) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    @include respond-below(sm) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
    }
    .WpdPostGrid {
        background: $white;
        display: flex;
        flex-direction: column;
        .WpdPostGridThumbnail {
            transition: filter 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
            will-change: filter;
            padding-top: 56.25%;
            display: flex;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
        .WpdPostGridDetails {
            padding: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include respond-below(xxl) {
                padding: 15px 20px;
            }
            .WpdPostGridTitle {
                font-size: 1.125rem;
                color: $dark;
                font-weight: 500;
                line-height: 1.555555;
            }
            .WpdPostGridContent {
                font-size: 0.75rem;
                color: $secondary;
                line-height: 1.6;
                margin-top: 20px;
                margin-bottom: 30px;
            }
        }
        &:hover {
            .WpdPostGridThumbnail {
                filter: brightness(120%) hue-rotate(10deg);
            }
            .WpdPostGridDetails {
                .WpdPostGridTitle {
                    color: darken($dark, 2%);
                }
            }
        }
    }
}

// Info Box
.WpdInfoBoxWrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    @include respond-below(hl) {
        grid-template-columns: repeat(4, 1fr);
    }
    @include respond-below(xxl) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include respond-below(lg) {
        grid-gap: 20px;
    }
    @include respond-below(md) {
        grid-gap: 15px;
    }
    .WpdInfoBox {
        display: flex;
        flex-direction: column;
        .WpdInfoBoxIcon {
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            flex: 1;
            @include respond-below(md) {
                padding: 15px;
            }
            svg,
            img {
                width: 100%;
                max-width: 150px;
                display: flex;
            }
        }
        .WpdInfoBoxTitle {
            font-size: 1rem;
            font-weight: 400;
            color: $secondary;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 15px 25px;
            position: relative;
            z-index: 1;
            @include respond-below(md) {
                padding-bottom: 15px;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 100%;
                left: 0;
                right: 0;
                background: $white;
                @include transition();
                z-index: -1;
            }
        }
        &:hover {
            .WpdInfoBoxTitle {
                &:before {
                    bottom: 0;
                }
            }
        }
    }
}

// Counter Box
.WpdCounterCardWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 50px;
    @include respond-below(xxl) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include respond-below(lg) {
        grid-gap: 20px;
    }
    @include respond-below(md) {
        grid-gap: 15px;
    }
    .WpdCounterCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $white;
        box-shadow: 0 2px 5px rgba($dark, 0.1);
        .WpdCounterBox {
            display: flex;
            align-items: center;
            padding: 30px;
            .WpdCounterBoxIcon {
                font-size: 2rem;
                color: $secondary;
            }
            .WpdCounterBoxInner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:not(:first-child) {
                    margin-left: 20px;
                }
                .WpdCounterTitle {
                    font-size: 1rem;
                    color: $secondary;
                }
                .WpdCounterCount {
                    display: flex;
                    align-items: flex-end;
                    .WpdCounterCountNumber {
                        font-size: 2rem;
                        color: $dark;
                    }
                    .WpdCounterIncDec {
                        font-size: 0.875rem;
                        font-weight: 900;
                        &:before {
                            font-family: 'Font Awesome 5 Free';
                            margin-right: 5px;
                        }
                        &.WpdInc {
                            color: $success;
                            &:before {
                                content: '\f062';
                            }
                        }
                        &.WpdDec {
                            color: $danger;
                            &:before {
                                content: '\f063';
                            }
                        }
                        &:not(:first-child) {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .WpdCounterCardFooter {
            min-height: 40px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            background: darken($light, 1.5%);
        }
    }
}

.WpdSimpleInfoWrapper {
    display: block;
    width: 100%;
    .WpdSimpleInfo {
        font-size: 1rem;
        color: $dark;
        display: flex;
        align-items: center;
        line-height: 1.3;
        .WpdSimpleInfoLabel {
            color: $secondary;
            min-width: 200px;
            margin-right: 10px;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
    &.WpdSectionPanel {
        @include respond-below(xl) {
            padding: 30px;
            .WpdSectionTitleWrap {
                margin-bottom: 20px;
            }
        }
        .WpdSimpleInfo {
            font-size: 1rem;
            .WpdSimpleInfoLabel {
                width: 30%;
                min-width: 150px;
                white-space: nowrap;
                @include respond-below(xl) {
                    min-width: 100px;
                }
            }
        }
    }
}

.WpdFont18 {
    font-size: 1.125rem;
}
.WpdFont500 {
    font-weight: 500;
}
.WpdFont700 {
    font-weight: 700;
}
.WpdTextUnderline {
    text-decoration: underline;
}
.WpdCursorPointer {
    cursor: pointer;
}

.WpdDownloadItemWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    @include respond-below(lg) {
        grid-template-columns: repeat(1, 1fr);
    }
    .WpdDownloadInfoWrapper {
        background: $white;
        padding: 20px;
        color: #25396f;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        font-weight: 400;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        width: 100%;
        @include respond-below(hl) {
            padding: 15px;
        }
        @include respond-below(sm) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        .WpdDownloadInfo {
            display: flex;
            align-items: center;
            margin-right: 20px;
            @include respond-below(hl) {
                margin-right: 10px;
            }
            .WpdDownloadThumbnail {
                width: 70px;
                height: 70px;
                min-width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include respond-below(hl) {
                    height: 60px;
                    width: 60px;
                    min-width: 60px;
                }
                @include respond-below(xxl) {
                    width: 50px;
                    height: 50px;
                    min-width: 50px;
                }
                img {
                    display: flex;
                    max-width: 100%;
                    max-height: 100%;
                }
                .WpdProductTextIcon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-align: center;
                    background: linear-gradient(
                        265deg,
                        $info 0%,
                        $info-lighten 100%
                    );
                    color: set-text-color($info);
                    @include respond-below(hl) {
                        font-size: 1.25rem;
                    }
                    @include respond-below(xxl) {
                        font-size: 1.125rem;
                    }
                }
            }
            .WpdDownloadDetails {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 20px;
                .WpdDownloadTitle {
                    font-size: 1.25rem;
                    line-height: 1.1;
                    font-weight: 500;
                    color: $dark;
                    margin-top: 5px;
                    @include respond-below(hl) {
                        font-size: 1.125rem;
                    }
                    @include respond-below(xxl) {
                        font-size: 1.0625rem;
                    }
                }
                .WpdDownloadSemiTitle {
                    font-size: 0.875rem;
                    font-weight: 500;
                    color: $secondary;
                    margin-bottom: 5px;
                    @include respond-below(xxl) {
                        font-size: 0.75rem;
                    }
                    span {
                        &:not(:last-child) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}
.grecaptcha-badge {
    display: none;
}

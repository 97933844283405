.WpdContentAreaFooter {
    padding: 30px 50px;
    display: flex;
    align-items: center;
    @include respond-below(xxl) {
        padding: 20px 30px;
    }
    @include respond-below(sm) {
        padding: 20px 15px;
    }
}

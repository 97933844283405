.WpdEmptyCartBody.WpdDarkView {
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: $sidebar;
	.WpdEmptyCartHeader {
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: set-text-color($sidebar);
		width: 100%;
		background: rgba($white, 0.05);
		border-bottom: 1px solid rgba(#80bdff, 0.15);
		box-shadow: 0 0 5px #25396f26;
		.WpdEmptyCartHeaderWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 25px;
			padding-bottom: 25px;
			padding-left: 30px;
			padding-right: 30px;
			max-width: 1400px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			@include respond-below(hl) {
				max-width: 1170px;
			}
			@include respond-below(xl) {
				max-width: 1024px;
				padding-top: 20px;
				padding-bottom: 20px;
			}
			@include respond-below(sm) {
				padding-left: 15px;
				padding-right: 15px;
				padding-top: 15px;
				padding-bottom: 15px;
			}
			.WpdCompanyLogo {
				svg,
				img {
					height: 30px;
					@include respond-below(xs) {
						height: 25px;
					}
				}
			}
			.WpdCartCount {
				font-size: 30px;
				line-height: 1;
				position: relative;
				.WpcCountNo {
					position: absolute;
					top: -8px;
					right: -8px;
					background: $success;
					color: set-text-color($success);
					padding: 3px 6px;
					font-size: 14px;
					font-weight: 600;
					line-height: 1;
					border-radius: 10px;
					box-shadow: 0 0 3px rgba($white, 0.2);
				}
			}
		}
	}
	.WpdEmptyCartWrapper {
		max-width: 1400px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		padding: 40px 30px 10px;
		@include respond-below(hl) {
			max-width: 1170px;
		}
		@include respond-below(xl) {
			max-width: 1024px;
		}
		.WpdEmptyCartMessageWrapper {
			background: rgba($white, 0.05);
			border: 1px solid rgba(#80bdff, 0.15);
			box-shadow: 0 0 5px #25396f26;
			padding: 20px 35px 20px 40px;
			width: 100%;
			display: flex;
			align-items: center;
			border-radius: 10px;
			.WpdEmptyCartMessage {
				.WpdEmptyCartMessageTitle {
					font-size: 2.75rem;
					line-height: 1;
					font-weight: 500;
					letter-spacing: -0.03px;
					color: $white;
					@include respond-below(lg) {
						font-size: 2.5rem;
					}
					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}
				.WpdEmptyCartMessageText {
					font-size: 1.125rem;
					line-height: 1.7;
					color: $secondary;
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
			}
			img {
				width: 130px;
				max-width: 130px;
				margin-left: auto;
				@include respond-below(md) {
					display: none;
				}
			}
			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
		.WpdProductPromoSection {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 25px;
			width: 100%;
			@include respond-below(lg) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include respond-below(sm) {
				grid-template-columns: repeat(1, 1fr);
			}
			.WpdProductPromo {
				width: 100%;
				background: rgba($white, 0.05);
				border: 1px solid rgba(#80bdff, 0.15);
				box-shadow: 0 0 5px #25396f26;
				border-radius: 10px;
				.WpdProductPromoThumbnailWrapper {
					padding: 30px 10px 25px;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					display: flex;
					justify-content: center;
					text-align: center;
					align-items: center;
					.WpdProductPromoThumbnail {
						height: 120px;
						width: 120px;
						min-width: 120px;
						background: rgba($white, 0.1);
						border: 1px solid rgba(#80bdff, 0.3);
						border-radius: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							width: 65px;
							max-width: 65px;
						}
					}
				}
				.WpdProductPromoDetails {
					padding: 0 15px 20px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					text-align: center;
					.WpdProductPromoTitle {
						font-size: 1.5rem;
						line-height: 1;
						font-weight: 500;
						letter-spacing: -0.03px;
						color: $white;
						&:not(:last-child) {
							margin-bottom: 20px;
						}
					}
					.WpdProductPriceInfoWrapper {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 100%;
						position: relative;
						background: $sidebar;
						border: 1px solid rgba(#80bdff, 0.15);
						box-shadow: 0 0 5px #25396f26;
						border-radius: 8px;
						padding: 16px;
						.WpdCartSelect {
							width: 100%;
							z-index: 9;
							.WpdCartSelect__control {
								font-size: 1rem;
								background: rgba($white, 0.05);
								border-color: rgba(#80bdff, 0.15);
								box-shadow: 0 0 5px #25396f26;
								min-height: 40px;
								width: 100%;
								.WpdCartSelect__value-container {
									padding: 0 20px !important;
									> * {
										margin-left: 0;
									}
									.WpdCartSelect__single-value {
										color: $white;
									}
									.WpdCartSelect__placeholder {
										color: $secondary;
									}
									.WpdCartSelect__input {
										input {
											color: $white !important;
											&:disabled {
												background: transparent !important;
											}
										}
									}
								}
								.WpdCartSelect__indicators {
									.WpdCartSelect__dropdown-indicator,
									.WpdCartSelect__clear-indicator {
										opacity: 0.5;
										color: #ccc !important;
									}
									.WpdCartSelect__indicator-separator {
										opacity: 0.3;
									}
								}
								&--is-focused {
									border-color: #80bdff;
									box-shadow: 0 0 5px #25396f26;
								}
							}
							.WpdCartSelect--is-disabled {
								filter: blur(0.2px);
								.WpdCartSelect__indicators {
									opacity: 0.15;
								}
							}
							.WpdCheckoutSelect__menu {
								background: #212463;
								border-radius: 4px;
								z-index: 99 !important;
								.WpdCheckoutSelect__option--is-focused {
									background: rgba($white, 0.9);
									color: $dark;
								}
							}
						}
						.WpdProductPrice {
							color: $success;
							margin-top: 15px;
							line-height: 1.5;
						}
						.WpdProductPriceInfoDescription {
							color: $light;
							margin-top: 5px;
							line-height: 1.5;
							font-style: italic;
							font-size: 0.9375rem;
							li {
								text-align: center;
							}
						}
						&:not(:last-child) {
							margin-bottom: 15px;
						}
					}
				}
				&.WpdProductPromoAgency {
					grid-column-start: 2;
					grid-column-end: 4;
					@include respond-below(lg) {
						grid-column-start: 1;
						grid-column-end: 3;
					}
					@include respond-below(sm) {
						grid-column-start: 1;
						grid-column-end: 2;
					}
					.WpdCartSelect {
						.WpdCartSelect__single-value {
							left: 20px;
							right: 0;
						}
					}
				}
			}
		}
	}
}

// profile control dropdown
.WpdProfileDropdown {
    padding: 0;
    border: none;
    min-width: 210px !important;
    max-width: 240px !important;
    box-shadow: 0 5px 20px rgba($dark, 0.2);
    margin-top: 15px;
    ul {
        padding: 14px;
        li {
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, border);
            a,
            button {
                display: flex;
                line-height: 1;
                padding: 6px;
                align-items: center;
                cursor: pointer;
                .WpdNavigationIcon {
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                    border-radius: 10px;
                    font-size: 0.75rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 1;
                    background: linear-gradient(
                        180deg,
                        $info 0%,
                        $info-lighten 100%
                    );
                    color: set-text-color($info);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color);
                    &:before {
                        position: absolute;
                        z-index: -1;
                        content: '';
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 10px;
                        background: linear-gradient(
                            180deg,
                            $info 0%,
                            $info-lighten 100%
                        );
                        opacity: 0.1;
                        @include transition($duration: 0.1s);
                        @include transition-properties(opacity);
                    }
                }
                .WpdNavigationText {
                    margin-left: 15px;
                    color: $secondary;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.3;
                    @include transition($duration: 0.2s);
                    @include transition-properties(background, color, border);
                }
                &.Active,
                &:hover {
                    .WpdNavigationIcon {
                        background: transparent;
                        color: set-text-color($info);
                        background-clip: initial;
                        -webkit-background-clip: initial;
                        -webkit-text-fill-color: initial;
                        &:before {
                            opacity: 1;
                        }
                    }
                    .WpdNavigationText {
                        color: $primary;
                    }
                }
            }
            &:hover {
                background: $light;
            }
        }
    }
}

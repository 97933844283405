// color
$sidebar: #090a52 !default;

$primary: #131f4d !default;
$primary-lighten: #5b4e96 !default;

$secondary: #7c8db5 !default;
$secondary-lighten: #848484 !default;

$success: #2eb86e !default;
$success-lighten: #45d6a3 !default;

$danger: #ec008c !default;
$danger-lighten: #ff50b8 !default;

$info: #007cdb !default;
$info-lighten: #00c0ff !default;

$warning: #e04f5f !default;
$warning-lighten: #ff3f8f !default;

$yellow: #fe9923 !default;
$yellow-lighten: #ffc107 !default;

$dark: #25396f !default;
$black: #000 !default;

$light: #f5f7fd !default;
$white: #fff !default;

$checkout-secondary: #5a657d !default;
$checkout-border-color: #c7d4df !default;
$checkout-success: #2fbf4e !default;
$checkout-primary: #492bc4 !default;

// template-color
$template-colors: () !default;
$template-colors: map-merge(
    (
        'Primary': $primary,
        'Secondary': $secondary,
        'Success': $success,
        'Danger': $danger,
        'Yellow': $yellow,
        'Info': $info,
        'Warning': $warning,
        'Dark': $dark,
        'Black': $black,
        'Light': $light,
        'White': $white,
        'Border': $checkout-border-color,
    ),
    $template-colors
);

// theme-color
$theme-colors: () !default;
$theme-colors: map-merge(
    (
        'Primary': $primary,
        'Success': $success,
        'Danger': $danger,
        'Yellow': $yellow,
        'Info': $info,
        'Warning': $warning,
        'White': $white,
    ),
    $theme-colors
);

$theme-colors-lighten: () !default;
$theme-colors-lighten: map-merge(
    (
        'Primary': $primary-lighten,
        'Success': $success-lighten,
        'Danger': $danger-lighten,
        'Yellow': $yellow-lighten,
        'Info': $info-lighten,
        'Warning': $warning-lighten,
        'White': $white,
    ),
    $theme-colors-lighten
);

.WpdFormWrapper {
    .row {
        // margin-bottom: -25px;
        // @include respond-below(hl) {
        //   margin-bottom: -15px;
        // }
        > .col,
        > [class*='col-'] {
            margin-bottom: 25px;
            @include respond-below(hl) {
                margin-bottom: 15px;
            }
        }
    }
}

.WpdFormUpdating {
    .form-control,
    .WpdSelectOption,
    .WpdSiteUrlInputWrapper,
    .WpdSearchForm,
    .WpdDateRangePicker,
    .WpdCheckbox,
    .WpdRadio,
    .WpdSelect__control,
    .WpdCustomSelectNumber,
    .WpdImageSelector {
        filter: blur(0.4px);
        opacity: 0.5;
        pointer-events: none;
    }
}

.WpdFormGroup {
    display: flex;
    flex-direction: column;
    // height: 100%;
    justify-content: flex-start;
    .WpdFormLabel {
        font-size: 1rem;
        color: $secondary;
        margin-bottom: 15px;
        line-height: 1.3;
        @include respond-below(hl) {
            font-size: 1rem;
            margin-bottom: 10px;
        }
    }
}

.WpdFileBrowseWrapper {
    display: flex;
    align-items: center;
    .WpdFormLabel {
        font-size: 1.125rem;
        color: $secondary;
        line-height: 1.3;
        @include respond-below(hl) {
            font-size: 1rem;
        }
    }
}
.WpdFileBrowseButton {
    input[type='file'] {
        display: none;
    }
}

.WpdFileChooserGroup {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
    @include respond-below(md) {
        width: 100%;
    }
    .WpdFileChooser {
        cursor: pointer;
        input[type='checkbox'],
        input[type='radio'] {
            display: none;
        }
        input[type='checkbox'] + span,
        input[type='radio'] + span {
            position: relative;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center;
            background-size: cover;
            border: 1px solid rgba($dark, 0.25);
            @include respond-below(sm) {
                height: 50px;
                width: 50px;
            }
            .WpdViewButton,
            .WpdDeleteButton {
                position: absolute;
                height: 18px;
                width: 18px;
                border-radius: 3px;
                font-size: 0.625rem;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    display: flex;
                    align-items: center;
                }
            }
            .WpdDeleteButton {
                top: 3px;
                right: 3px;
                background: darken($light, 5%);
                color: darken($secondary, 10%);
                &:hover {
                    background: lighten($secondary, 17%);
                    color: $dark;
                }
            }
            .WpdViewButton {
                bottom: 3px;
                left: 3px;
                background: $info;
                color: $white;
            }
        }
        .WpdFilePreview {
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 5px;
                @include respond-below(sm) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
    .WpdFileBrowseButton {
        cursor: pointer;
        border: 1px solid rgba($dark, 0.1);
        color: $secondary !important;
        height: 60px;
        width: 60px;
        border-radius: 5px;
        padding: 0 25px;
        min-width: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($secondary, 0.1);
        @include respond-below(sm) {
            height: 50px;
            width: 50px;
            min-width: 50px !important;
            padding: 0 15px;
        }
        span {
            font-size: 1.375rem;
        }
        input {
            display: none;
        }
    }
    > * {
        margin: 3px;
    }
}

// form control
input.form-control {
    height: 46px;
    padding: 0 20px !important;
    font-size: 1.0675rem;
    color: $dark;
    background: $white;
    border-color: $white;
    &:focus {
        box-shadow: 0 0 5px rgba($dark, 0.15);
    }
    &::placeholder {
        color: $secondary;
    }
}

// form control
textarea.form-control {
    padding: 20px !important;
    font-size: 1.125rem;
    color: $dark;
    background: $white;
    border-color: $white;
    &:focus {
        box-shadow: 0 0 5px rgba($dark, 0.15);
    }
    &::placeholder {
        color: $secondary;
    }
}

.WpdAffiliateUrl {
    height: 46px;
    padding: 0 20px !important;
    font-size: 1.0675rem;
    color: $dark;
    background: $white;
    border-color: $white;
    border-radius: 0.25rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .WpdAffiliateUrlCopyButton {
        margin-left: 20px;
        color: $info;
        cursor: pointer;
    }
}

// for error message
.WpdHasError {
    border: 1px solid $warning !important;
    &:focus {
        box-shadow: 0 0 5px rgba($warning, 0.15) !important;
    }
}

// select option
.WpdSelectOption {
    position: relative;
    background: $white;
    min-height: 46px;
    .react-select__control {
        padding-left: 13px;
        min-height: 47px;
        width: 100%;
        font-size: 1.0675rem;
        font-weight: 500;
        line-height: 1.4286;
        font-family: unset;
        border: 1.5px solid transparent;
        background: transparent;
        color: $dark;
        appearance: none;
        @include respond-below(hl) {
            min-height: 43px !important;
        }
        @include respond-below(xxl) {
            min-height: 37px !important;
        }
        .react-select__value-container {
            padding: 0 !important;
        }
        &--is-focused {
            box-shadow: 0 0 5px rgba($dark, 0.15) !important;
        }
    }
}

.WpdSiteUrlInputWrapper {
    display: flex;
    justify-content: flex-start;
    input.form-control {
        background: darken($light, 1.5%);
        max-width: 400px;
        border-radius: 50px;
        margin-bottom: 0;
    }
    .WpdButton {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

// search form
.WpdSearchForm {
    position: relative;
    height: 46px;
    min-width: 300px;
    background: $white !important;
    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
    &:after {
        font-family: 'WP-Insight';
        content: '\e911';
        position: absolute;
        pointer-events: none;
        font-size: 1.125rem;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        color: $secondary;
        margin-left: 20px !important;
    }
}

// date picker
.WpdDateRangePicker {
    width: 100% !important;
    .WpdDateRangePickerInput {
        width: 100% !important;
        @extend .form-control;
        font-family: unset !important;
        padding: 0 !important;
        .WpdDataRangePickerInput__arrow {
            width: 30px !important;
        }
        .WpdDateInput {
            background: unset !important;
            height: 100% !important;
            width: calc(50% - 30px) !important;
            .WpdDateInput_input__focused,
            .WpdDateInput_input {
                background: unset !important;
                font-size: unset !important;
                font-weight: unset !important;
                line-height: unset !important;
                height: 100% !important;
                padding: 0 15px !important;
            }
        }
    }
}
.WpdDatePickerWrapper {
    position: relative;
    .WpdCloseButton {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
    }
}

.WpdCheckbox {
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    @include transition;
    .WpdText {
        font-size: 1rem;
        color: $secondary;
        display: inline-flex;
        align-items: flex-start;
        line-height: 24px;
        position: relative;
        padding-left: 35px;
        flex-wrap: wrap;
        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    input[type='checkbox'] {
        display: none;
        + .WpdText:before {
            position: absolute;
            top: 2px;
            left: 0;
            content: '';
            color: $secondary;
            background: transparent;
            border: 1px solid $secondary;
            border-radius: 4px;
            height: 20px;
            width: 20px;
            min-width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: 'Wpd-Icon' !important;
            font-size: 0.625rem;
        }
        &:checked + .WpdText:before {
            content: '\e912';
            line-height: 1;
            color: set-text-color($primary);
            background: $primary;
            border-color: $primary;
        }
    }
}

.WpdRadio {
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    @include transition;
    .WpdText {
        font-size: 1.125rem;
        font-weight: 400;
        color: $secondary;
        display: inline-flex;
        align-items: flex-start;
        line-height: 24px;
        position: relative;
        padding-left: 35px;
        flex-wrap: wrap;
        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    input[type='radio'] {
        display: none;
        + .WpdText:before {
            position: absolute;
            top: 2px;
            left: 0;
            content: '';
            color: $secondary;
            background: transparent;
            border: 1px solid $secondary;
            border-radius: 10px;
            height: 20px;
            width: 20px;
            min-width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: 'Wpd-Icon' !important;
            font-size: 0.625rem;
        }
        &:checked + .WpdText:before {
            content: '\e912';
            line-height: 1;
            color: set-text-color($primary);
            background: $primary;
            border-color: $primary;
        }
    }
}

.WpdCardChecker {
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    @include transition;
    .WpdText {
        font-size: 1.125rem;
        font-weight: 400;
        color: $checkout-border-color;
        display: inline-flex;
        align-items: flex-start;
        line-height: 24px;
        flex-wrap: wrap;
        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    input[type='checkbox'],
    input[type='radio'] {
        display: none;
        + .WpdText:before {
            display: flex;
            content: '';
            color: $checkout-border-color;
            background: transparent;
            border: 1px solid $checkout-border-color;
            border-radius: 10px;
            height: 20px;
            width: 20px;
            min-width: 20px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: 'Wpd-Icon' !important;
            font-size: 0.625rem;
        }
        &:checked + .WpdText:before {
            content: '\e912';
            color: set-text-color($success);
            background: $success;
            border-color: $success;
        }
    }
}

// for disable
input:disabled {
    cursor: no-drop;
    background-color: darken($light, 3%) !important;
}

.WpdCustomSelectNumber {
    margin: 0 7px;
    .custom-select {
        padding: 2px 25px 2px 14px !important;
        height: 30px !important;
        color: $primary;
        font-weight: 500;
        @include respond-below(sm) {
            height: 20px !important;
            padding: 0 20px 0 10px !important;
        }
    }
}

.WpdImageSelector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .WpdImgBox {
        background: $secondary;
        height: 150px;
        width: 150px;
        min-width: 150px;
        overflow: hidden;
        position: relative;
        margin-right: 30px;
        @include respond-below(hl) {
            margin-right: 20px;
            height: 120px;
            width: 120px;
            min-width: 120px;
        }
        @include respond-below(xxl) {
            margin-right: 20px;
            height: 100px;
            width: 100px;
            min-width: 100px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
        .WpdRemoveButton {
            position: absolute;
            top: 5px;
            right: 5px;
            background: $danger;
            color: $white;
            height: 16px;
            width: 16px;
            font-size: 8px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.WpdHide {
                display: none;
            }
        }
    }
    .WpdFileBrowseButton {
        margin-top: 15px;
        margin-bottom: 15px;
        .WpdSmallButton {
            .WpdButtonInner {
                background: $light;
            }
        }
        input {
            display: none;
        }
        :hover {
            color: darken($primary, 20%);
        }
    }
}

.WpdPreText {
    padding: 30px;
    background: rgba($primary, 0.02);
    border: 1px solid rgba($primary, 0.05);
    color: $primary;
    font-size: 1rem;
    line-height: 1.7;
}

.WpdErrorMessage {
    color: $warning;
    margin-top: 5px;
}

// react select style overwrite for dark mood
.WpdSelect__control {
    font-size: 1rem;
    background: $white;
    border-color: transparent !important;
    min-height: 46px !important;
    .WpdSelect__value-container {
        padding: 0 20px !important;
        > * {
            margin-left: 0;
        }
        .WpdSelect__single-value {
            color: $dark;
        }
        .WpdSelect__placeholder {
            color: $secondary;
        }
        .WpdSelect__input {
            input {
                color: $dark !important;
                &:disabled {
                    background: transparent !important;
                }
            }
        }
    }
    .WpdSelect__indicators {
        .WpdSelect__dropdown-indicator,
        .WpdSelect__clear-indicator {
            color: $secondary !important;
        }
    }
    &--is-focused {
        border-color: #80bdff;
        box-shadow: 0 0 5px #25396f26;
    }
}
.WpdSelect--is-disabled {
    filter: blur(0.2px);
    .WpdSelect__control {
        background: lighten($secondary, 34%) !important;
        .WpdSelect__value-container {
            opacity: 0;
        }
    }
    .WpdSelect__indicators {
        opacity: 0.3;
    }
}
.WpdSelect__menu {
    background-color: $white !important;
    border-radius: 4px !important;
    border-color: $secondary;
    box-shadow: 0 0 5px rgba($dark, 0.2) !important;
    z-index: 9 !important;
    .WpdSelect__menu-list {
        border-radius: 4px !important;
    }
    .WpdSelect__option--is-focused,
    .WpdSelect__option--is-selected {
        background: #2684ff !important;
        color: $white !important;
    }
}
.WpdSearch {
    display: flex;
    input {
        height: 46px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .WpdSearchButton {
        height: 46px;
        padding: 0 15px;
        line-height: 46px;
        background: $info;
        color: #fff;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}
// end react select style overwrite for dark mood

$pagination-prefix-cls: rc-pagination;

.#{$pagination-prefix-cls} {
    display: flex;
    > *:not(:last-child) {
        margin-right: 5px;
    }
    &:after {
        display: block;
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        content: '';
    }
    &-total-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        @include respond-below(xl) {
            height: 30px;
        }
    }
    &-item {
        display: flex;
        min-width: 40px;
        height: 40px;
        font-size: 1rem;
        font-weight: 500;
        background: $white;
        color: $dark;
        cursor: pointer;
        user-select: none;
        border-width: 0;
        box-shadow: 0 0 10px rgba($dark, 0.075);
        @include respond-below(xl) {
            height: 30px;
            min-width: 30px;
        }
        button,
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: $dark;
            transition: none;
        }
        &:focus,
        &-active,
        &:hover {
            background: $white;
            a {
                color: darken($info, 5%);
            }
        }
    }

    &-jump-prev,
    &-jump-next {
        cursor: pointer;
        user-select: none;
        .#{$pagination-prefix-cls}-item-link,
        button {
            &:after {
                display: block;
                content: '•••';
                color: $dark;
                font-size: 1rem;
            }
        }
        &:focus,
        &:hover {
            background: $white;
            .#{$pagination-prefix-cls}-item-link,
            button {
                &:after {
                    color: darken($info, 5%);
                }
            }
        }
    }
    &-prev,
    &-next,
    &-jump-prev,
    &-jump-next {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        height: 40px;
        color: $dark;
        background: $white;
        cursor: pointer;
        box-shadow: 0 0 10px rgba($dark, 0.075);
        font-size: 20px;
        @include respond-below(xl) {
            height: 30px;
            min-width: 30px;
        }
    }

    &-prev,
    &-next {
        button {
            color: darken($info, 5%);
            cursor: pointer;
            user-select: none;
        }
        .#{$pagination-prefix-cls}-item-link {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: darken($info, 5%);
            background: $white;
        }
        &:focus .#{$pagination-prefix-cls}-item-link,
        &:hover .#{$pagination-prefix-cls}-item-link {
            background: $white;
            color: darken($info, 5%);
        }
    }
    &-prev button:after {
        content: '\e900';
        font-family: 'Wpd-Icon';
        font-size: 20px;
        display: block;
        transform: rotate(90deg);
    }

    &-next button:after {
        content: '\e900';
        font-family: 'Wpd-Icon';
        font-size: 20px;
        display: block;
        transform: rotate(-90deg);
    }

    &-disabled {
        background-color: darken($white, 3%);
        color: $secondary;
        box-shadow: 0 0 25px rgba($dark, 0.125);
        &:hover,
        &:focus {
            cursor: not-allowed;
        }
    }

    // &-slash {
    // 	margin: 0 10px 0 5px;
    // }

    // &-options {
    // 	display: inline-block;
    // 	margin-left: 16px;
    // 	vertical-align: middle;

    // 	// IE11 css hack. `*::-ms-backdrop,` is a must have
    // 	@media all and (-ms-high-contrast: none) {
    // 	*::-ms-backdrop,
    // 	& {
    // 		vertical-align: top;
    // 	}
    // 	}

    // 	&-size-changer.rc-select {
    // 	display: inline-block;
    // 	width: auto;
    // 	margin-right: 8px;
    // 	}

    // 	&-quick-jumper {
    // 	display: inline-block;
    // 	height: 28px;
    // 	line-height: 28px;
    // 	vertical-align: top;

    // 	input {
    // 		width: 50px;
    // 		margin: 0 8px;
    // 	}
    // 	}
    // }

    &-simple &-prev,
    &-simple &-next {
        height: 40px;
        @include respond-below(xl) {
            height: 30px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        .#{$pagination-prefix-cls}-item-link {
            height: 40px;
            @include respond-below(xl) {
                height: 30px;
            }
            &::after {
                height: 40px;
                @include respond-below(xl) {
                    height: 30px;
                }
            }
        }
    }

    // &-simple &-simple-pager {
    // 	display: inline-block;
    // 	height: 40px;
    // @include respond-below(xl){
    // 	height: 30px;
    // }
    // 	margin-right: 8px;
    // 	input {
    // 		box-sizing: border-box;
    // 		height: 100%;
    // 		margin-right: 8px;
    // 		padding: 0 6px;
    // 		text-align: center;
    // 		background-color: $pagination-item-input-bg;
    // 		border: 1px solid #d9d9d9;
    // 		outline: none;
    // 		transition: border-color 0.3s;
    // 		&:hover {
    // 			border-color: $primary;
    // 		}
    // 	}
    // }

    // ============================ Disabled ============================
    // &-disabled {
    // 	cursor: not-allowed;
    // 	.#{$pagination-prefix-cls}-item {
    // 		background: hsv(0, 0, 96%);
    // 		border-color: #d9d9d9;
    // 		cursor: not-allowed;
    // 		a {
    // 			color: fade(#000, 25%);
    // 			background: transparent;
    // 			border: none;
    // 			cursor: not-allowed;
    // 		}
    // 		&-active {
    // 			background: darken($info, 5%);
    // 			border-color: transparent;
    // 			a {
    // 			color: $pagination-item-disabled-color-active;
    // 			}
    // 		}
    // 	}
    // 	.#{$pagination-prefix-cls}-item-link {
    // 		color: fade(#000, 25%);
    // 		background: hsv(0, 0, 96%);
    // 		border-color: #d9d9d9;
    // 		cursor: not-allowed;
    // 	}
    // 	.#{$pagination-prefix-cls}-item-link-icon {
    // 		opacity: 0;
    // 	}
    // 	.#{$pagination-prefix-cls}-item-ellipsis {
    // 		opacity: 1;
    // 	}
    // }
}

.WpdCheckoutHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	.WpdCheckoutHeaderWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 20px;
		padding-bottom: 20px;
		width: 100%;
		max-width: 2200px;
		margin-left: auto;
		margin-right: auto;
		min-height: 120px;
		@include respond-below(xl) {
			padding-top: 20px;
			padding-bottom: 20px;
			min-height: 115px;
		}
		@include respond-below(md) {
			min-height: 0;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.WpdCompanyLogo {
			svg,
			img {
				height: 35px;
				@include respond-below(xs) {
					height: 30px;
				}
			}
		}
		.WpdCheckoutHeaderLeft {
			padding-top: 20px;
			padding-bottom: 20px;
			@include respond-below(md) {
				padding-top: 10px;
				padding-bottom: 10px;
			}
			.WpdCheckoutHeaderTitle {
				font-size: 3rem;
				line-height: 1;
				font-weight: 500;
				letter-spacing: -0.03px;
				color: $primary;
			}
			.WpdCheckoutHeaderSemiTitle {
				font-size: 2.25rem;
				line-height: 1;
				font-weight: 500;
				letter-spacing: -0.03px;
				color: $primary;
			}
			&.WpdFilled {
				.WpdCheckoutHeaderTitle,
				.WpdCheckoutHeaderSemiTitle {
					color: set-text-color($sidebar) !important;
				}
			}
		}
		.WpdCheckoutHeaderRight {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.WpdCheckoutHeaderRightIcon {
				margin-right: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				svg,
				img {
					width: 45px;
					display: inline-flex;
					@include respond-below(xl) {
						width: 40px;
					}
				}
				@include respond-below(xl) {
					margin-right: 20px;
				}
			}
			.WpdCheckoutHeaderRightContent {
				.WpdCheckoutHeaderRightTitle {
					font-size: 1.375rem;
					font-weight: 500;
					line-height: 1.3;
					color: $primary;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				.WpdCheckoutHeaderRightInfo {
					font-size: 0.875rem;
					font-weight: 400;
					line-height: 1.5;
					color: $secondary;
					@include respond-below(xl) {
						br {
							display: none;
						}
					}
				}
			}
			&.WpdFilled {
				.WpdCheckoutHeaderRightTitle {
					color: set-text-color($sidebar) !important;
				}
			}
		}
	}
	&.WpdFilled {
		background: $sidebar;
		color: set-text-color($sidebar);
		.WpdCheckoutHeaderWrapper {
			padding-top: 25px;
			padding-bottom: 25px;
			.WpdCheckoutHeaderTitle,
			.WpdCheckoutHeaderSemiTitle,
			.WpdCheckoutHeaderRightTitle {
				color: set-text-color($sidebar) !important;
			}
			@include respond-below(xl) {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}
	}
}
.WpdCheckoutContent {
	display: flex;
	flex: 1;
	width: 100%;
	max-width: 2200px;
	margin-left: auto;
	margin-right: auto;
	@include respond-below(md) {
		flex-wrap: wrap;
	}
	.WpdSectionGap {
		margin-top: 30px;
		@include respond-below(xl) {
			margin-top: 20px;
		}
	}
	.WpdDataTableWrapper {
		margin-top: -2.5px;
		margin-bottom: -2.5px;
		@include respond-below(md) {
			margin-top: -1px;
			margin-bottom: -1px;
		}
		.WpdTableTitle {
			margin-top: 10px;
			min-height: 50px;
			padding: 0 20px;
			width: 100%;
			display: flex;
			align-items: center;
			background: $white;
			font-size: 0.875rem;
			color: $dark;
			@include respond-below(xl) {
				padding: 0 10px;
			}
			@include respond-below(lg) {
				margin-bottom: 5px;
			}
		}
		.WpdDataTable {
			border-spacing: 0 5px;
			@include respond-below(md) {
				border-spacing: 2px;
			}
			thead {
				tr {
					height: 60px;
					th {
						padding: 10px 20px;
						font-size: 1rem;
						color: $dark;
						@include respond-below(xl) {
							padding: 10px;
						}
					}
				}
			}
			tbody {
				tr {
					td {
						padding: 10px 20px;
						min-height: 50px;
						@include respond-below(xl) {
							padding: 10px;
						}
						&.WpdTableProductInfoColumn {
							width: 45%;
							.WpdTableProductInfoWrapper {
								.WpdTableProductInfo {
									@include respond-below(xl) {
										align-items: center;
										margin: 0;
									}
									.WpdTableProductThumbnail {
										width: 50px;
										height: 50px;
										min-width: 50px;
									}
									.WpdTableProductDetails {
										margin-left: 30px;
										.WpdTableProductTitle {
											font-size: 1.25rem;
											font-weight: 500;
											color: $dark;
											margin-top: 0;
											@include respond-below(hl) {
												font-size: 1.125rem;
											}
											@include respond-below(xxl) {
												font-size: 1.0625rem;
											}
										}
										.WpdTableProductSemiTitle {
											font-size: 0.875rem;
											font-weight: 400;
											color: $secondary;
											margin-top: 5px;
											@include respond-below(xxl) {
												font-size: 0.75rem;
											}
										}
									}
								}
							}
						}
					}
					&.WpdTotalCount {
						td {
							border-right: 0px;
							font-size: 1.125rem;
							font-weight: 500;
							color: $dark;
						}
					}
				}
			}
		}
	}
	.WpdPurchaseButton {
		background: $success-lighten;
		min-height: 52px;
		padding: 10px 40px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		font-size: 1.375rem;
		font-weight: 500;
		line-height: 1.1;
		text-align: center;
		letter-spacing: -0.02;
		color: $white;
		text-shadow: 0 3px 4px rgba($dark, 0.4);
		@include transition;
		&:hover,
		&:focus {
			background: darken($success-lighten, 3%);
			text-shadow: 0 4px 4px rgba($dark, 0.7);
			box-shadow: 0 10px 20px rgba($success-lighten, 0.3);
		}
		@include respond-below(xxl) {
			min-height: 46px;
			padding: 5px 35px;
			font-size: 1.25rem;
		}
	}
	.WpdSectionPanel {
		padding: 30px;
		.WpdFormWrapper {
			.row {
				margin-bottom: -15px;
				> .col,
				> [class*='col-'] {
					margin-bottom: 15px;
					.WpdFormGroup {
						.WpdFormLabel {
							margin-bottom: 10;
						}
					}
				}
			}
			.WpdFormGroup {
				.form-control {
					height: 50px;
					border-radius: 10px;
					font-size: 1rem;
					border-color: lighten($secondary, 30%);
					padding: 0 30px !important;
					@include respond-below(xl) {
						height: 55px;
					}
					&:focus {
						border-color: #80bdff;
					}
				}
			}
		}
	}
	.WpdCheckoutContentLeft {
		flex: 1;
		padding: 50px;
		padding-top: 0;
		width: calc(100% - 800px);
		@include respond-below(hl) {
			width: calc(100% - 700px);
		}
		@include respond-below(xxl) {
			width: calc(100% - 600px);
			padding: 40px;
			padding-top: 0;
		}
		@include respond-below(xl) {
			width: calc(100% - 500px);
			padding: 30px;
			padding-top: 0;
		}
		@include respond-below(lg) {
			width: calc(100% - 360px);
		}
		@include respond-below(md) {
			padding: 20px;
			padding-top: 0;
			width: 100%;
		}
		@include respond-below(xs) {
			padding: 15px;
			padding-top: 0;
		}
		.WpdLicenseSection {
			background: $white;
			.WpdLicenseSectionTitle {
				font-size: 1.375rem;
				font-weight: 500;
				line-height: 1.3;
				color: $dark;
				padding: 20px;
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				.WpdCollapserToggler {
					color: $info;
					transform-origin: center center;
					display: flex;
					align-items: center;
				}
				&.Expanded {
					.WpdCollapserToggler {
						transform: rotateX(180deg);
					}
				}
			}
			.WpdLicenseSectionBody {
				padding: 20px;
				border-top: 1px solid rgba($secondary, 0.1);
				.WpdLicenseSectionInfo {
					font-size: 1.125rem;
					font-weight: 400;
					line-height: 1.7;
					color: $secondary;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				.WpdFormGroup {
					input.form-control {
						font-size: 1rem;
						background: $light;
						border-color: $light;
						&:focus {
							border-color: #80bdff;
						}
					}
					&:not(:last-child) {
						margin-bottom: 20px;
						@include respond-below(xl) {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.WpdUpSellSection {
			background: #e4f4f4;
			padding: 20px 15px;
			border-radius: 5px;
			border: 1.5px dashed #60dbb0;
			.WpdUpSellChecker {
				display: inline-flex;
				align-items: center;
				cursor: pointer;
				@include transition;
				.WpdUpSellCheckerText {
					font-size: 1.25rem;
					font-weight: 500;
					color: $sidebar;
					display: inline-flex;
					align-items: flex-start;
					line-height: 24px;
					position: relative;
					padding-left: 30px;
					flex-wrap: wrap;
					a {
						margin-left: 5px;
						margin-right: 5px;
					}
				}
				input[type='checkbox'] {
					display: none;
					+ .WpdUpSellCheckerText:before {
						position: absolute;
						top: 2px;
						left: 0;
						content: '';
						color: #46d6a3;
						background: transparent;
						border: 2px solid #46d6a3;
						border-radius: 4px;
						height: 20px;
						width: 20px;
						min-width: 20px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						font-family: 'Wpd-Icon' !important;
						font-size: 0.625rem;
					}
					&:checked + .WpdUpSellCheckerText:before {
						content: '\e912';
						line-height: 1;
						color: set-text-color(#46d6a3);
						background: #46d6a3;
						border-color: #46d6a3;
					}
				}
			}
			.WpdUpSellText {
				color: #586992;
				padding-left: 30px;
				padding-top: 10px;
				font-size: 1rem;
				line-height: 1.6;
				font-weight: 500;
				b {
					color: $sidebar;
				}
				a {
					color: $info;
				}
			}
		}
		.WpdRattingSection {
			padding: 50px;
			@include respond-below(xl) {
				padding: 30px;
			}
			.WpdRattingSubmitter {
				display: flex;
				.WpdRattingSubmitterImage {
					display: inline-flex;
					height: 35px;
					width: 35px;
					min-width: 35px;
					border-radius: 50%;
					background: rgba($secondary, 0.3);
					svg,
					img {
						display: inline-flex;
						border-radius: 50px;
						width: 100%;
						height: 100%;
					}
				}
				.WpdRattingSubmitterInfo {
					.WpdRattingSubmitterTitle {
						font-size: 0.875rem;
						font-weight: 500;
						color: $dark;
						line-height: 1.3;
					}
					.WpdRattingSubmitterRate {
						display: flex;
						margin-top: 5px;
						.WpdRate {
							font-size: 0.75rem;
							line-height: 1;
							color: rgba($secondary, 0.5);
							&:not(:last-child) {
								margin-right: 5px;
							}
							&.WpdFilled {
								color: $yellow-lighten;
							}
						}
					}
					&:not(:first-child) {
						margin-left: 15px;
					}
				}
			}
			.WpdReview {
				margin-top: 15px;
				font-size: 0.75rem;
				line-height: 1.7;
				font-style: italic;
				color: $secondary;
			}
		}
	}
	.WpdCheckoutContentRight.WpdRightFilled {
		width: 800px;
		background: $sidebar;
		padding: 50px;
		padding-top: 0;
		@include respond-below(hl) {
			width: 700px;
		}
		@include respond-below(xxl) {
			width: 600px;
			padding: 40px;
			padding-top: 0;
		}
		@include respond-below(xl) {
			width: 500px;
			padding: 30px;
			padding-top: 0;
			padding-bottom: 50px;
		}
		@include respond-below(lg) {
			width: 360px;
		}
		@include respond-below(md) {
			padding: 20px;
			padding-top: 0;
			width: 100%;
		}
		@include respond-below(xs) {
			padding: 15px;
			padding-top: 0;
		}
		.WpdPaymentMethodSection {
			padding: 30px;
			background: rgba($white, 0.05);
			color: $white;
			&:not(:first-child) {
				margin-top: 5px;
			}
			@include respond-between(md, lg) {
				padding: 20px;
			}
			@include respond-below(sm) {
				padding: 15px;
			}
			.WpdPaymentMethodSectionTitle {
				font-size: 1.375rem;
				font-weight: 500;
				line-height: 1.3;
				color: $white;
				margin-bottom: 20px;
				@include respond-below(sm) {
					margin-bottom: 10px;
				}
			}
			.WpdFormWrapper {
				.row {
					margin-bottom: -15px;
					> .col,
					> [class*='col-'] {
						margin-bottom: 15px;
						.WpdFormGroup {
							.WpdFormLabel {
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}
		.WpdPaymentMethodSelector {
			display: flex;
			justify-content: space-between;
			@include respond-below(lg) {
				flex-wrap: wrap;
				margin-bottom: -10px;
			}
			.WpdRadio {
				width: calc(50% - 10px);
				min-width: 200px;
				@include respond-below(xxl) {
					min-width: 180px;
				}
				@include respond-below(lg) {
					margin-bottom: 10px;
				}
				@include respond-between(md, lg) {
					width: 100%;
				}
				@include respond-between(xs, sm) {
					width: calc(50% - 5px);
				}
				@include respond-below(xs) {
					width: 100%;
				}
				.WpdText {
					min-height: 60px;
					width: 100%;
					border-radius: 10px;
					background: rgba($white, 0.05);
					border: 2px solid rgba($light, 0.1);
					display: inline-flex;
					justify-content: flex-start;
					text-align: right;
					align-items: center;
					padding: 10px 20px;
					position: relative;
					font-size: 1.25rem;
					font-weight: 500;
					color: $white;
				}
				input[type='radio'] {
					display: none;
					+ .WpdText:before {
						position: absolute;
						top: 50%;
						left: calc(100% - 35px);
						transform: translateY(-50%);
						content: '';
						color: $secondary;
						background: transparent;
						border: 1px solid $secondary;
						border-radius: 10px;
						height: 20px;
						width: 20px;
						min-width: 20px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						font-family: 'Wpd-Icon' !important;
						font-size: 0.625rem;
						pointer-events: none;
					}
					+ .WpdText:after {
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
						content: '';
						color: transparent;
						background: transparent;
						border: 1px solid transparent;
						border-radius: 10px;
						height: 10px;
						width: 10px;
						min-width: 10px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						font-family: 'Wpd-Icon' !important;
						font-size: 0.625rem;
						pointer-events: none;
					}
					&:checked {
						& + .WpdText {
							border-color: rgba($success-lighten, 0.5);
							background: rgba($success-lighten, 0.2);
							&:before {
								color: $secondary;
								background: transparent;
								border: 1px solid $success-lighten;
							}
							&:after {
								color: $secondary;
								background: $success-lighten;
								border: 1px solid $success-lighten;
							}
						}
					}
				}
			}
		}
		.WpdFormGroup {
			input.form-control {
				font-size: 1rem;
				background: rgba($white, 0.05);
				border-color: transparent;
				color: $white;
				&::placeholder {
					color: $secondary;
				}
				&:focus {
					border-color: #80bdff;
				}
				&:disabled {
					background-color: rgba($white, 0.05) !important;
					border-color: transparent;
					color: $white;
					padding: 0 30px;
					cursor: initial;
				}
			}
		}

		// react select style overwrite for dark mood
		.WpdCheckoutSelect__control {
			font-size: 1rem;
			background: rgba($white, 0.05);
			border-color: transparent;
			min-height: 46px;
			.WpdCheckoutSelect__value-container {
				padding: 0 20px !important;
				> * {
					margin-left: 0;
				}
				.WpdCheckoutSelect__single-value {
					color: $white;
				}
				.WpdCheckoutSelect__placeholder {
					color: $secondary;
				}
				.WpdCheckoutSelect__input {
					input {
						color: $white !important;
						&:disabled {
							background: transparent !important;
						}
					}
				}
			}
			.WpdCheckoutSelect__indicators {
				.WpdCheckoutSelect__dropdown-indicator,
				.WpdCheckoutSelect__clear-indicator {
					color: #ccc !important;
				}
			}
			&--is-focused {
				border-color: #80bdff;
				box-shadow: 0 0 5px #25396f26;
			}
		}
		.WpdCheckoutSelect--is-disabled {
			filter: blur(0.2px);
			.WpdCheckoutSelect__indicators {
				opacity: 0.15;
			}
		}
		.WpdCheckoutSelect__menu {
			background: #212463;
			border-radius: 4px;
			z-index: 9 !important;
			.WpdCheckoutSelect__option--is-focused {
				background: rgba($white, 0.9);
				color: $dark;
			}
		}
		// end react select style overwrite for dark mood

		.WpdCheckbox {
			.WpdText {
				color: $light;
				font-weight: 400;
				a {
					color: $white;
					font-style: italic;
					text-decoration: underline;
				}
			}
			input[type='checkbox'] {
				+ .WpdText:before {
					background: rgba($light, 0.05);
					border-color: rgba($secondary, 0.5);
				}
				&:checked + .WpdText:before {
					color: set-text-color($success-lighten);
					background: $success-lighten;
					border-color: $success-lighten;
				}
			}
		}
	}

	.WpdPurchaseTotal {
		background-color: rgba(255, 255, 255, 0.05) !important;
		border-color: transparent;
		color: #fff;
		font-size: 1rem;
		height: 46px;
		padding: 0 20px;
		line-height: 46px;
	}
}
.WpdNewCheckoutContent {
	display: flex;
	flex: 1;
	width: 100%;
	max-width: 2200px;
	margin-left: auto;
	margin-right: auto;
	@include respond-below(md) {
		flex-wrap: wrap;
	}
	.WpdSectionTitleWrap {
		margin-bottom: 30px;
		@include respond-above(ml) {
			margin-bottom: 50px;
		}
		@include respond-below(hl) {
			margin-bottom: 20px;
		}
		@include respond-below(xxl) {
			margin-bottom: 15px;
		}
		@include respond-below(xl) {
			margin-bottom: 10px;
		}
	}
	.WpdCheckoutTitle {
		font-size: 2.5rem;
		color: $sidebar;
		font-weight: 700;
		line-height: 1;
		@include respond-above(ml) {
			font-size: 3.125rem;
		}
		@include respond-below(hl) {
			font-size: 2.25rem;
		}
		@include respond-below(xl) {
			font-size: 2rem;
		}
	}
	.WpdCheckoutSubTitle {
		font-size: 1.875rem;
		color: $sidebar;
		font-weight: 500;
		line-height: 1;
		@include respond-above(ml) {
			font-size: 2.125rem;
		}
		@include respond-below(hl) {
			font-size: 1.75rem;
		}
		@include respond-below(xl) {
			font-size: 1.5rem;
		}
	}
	.WpdSectionGap {
		margin-top: 25px;
		@include respond-above(ml) {
			margin-top: 30px;
		}
		@include respond-below(hl) {
			margin-top: 20px;
		}
		@include respond-below(md) {
			margin-top: 15px;
		}
	}
	.WpdSignInMessage {
		font-size: 1.25rem;
		font-weight: 400;
		color: $dark;
		line-height: 1.3;
		letter-spacing: -0.5px;
		@include respond-below(hl) {
			font-size: 1.125rem;
		}
		@include respond-below(xl) {
			font-size: 1rem;
		}
		a {
			font-weight: 500;
			color: $checkout-primary;
			font-style: italic;
			text-decoration: underline;
		}
	}
	.WpdCheckoutProductWrapper {
		.WpdCheckoutProduct {
			background: $white;
			border-radius: 5px;
			box-shadow: 0 15px 50px rgba($dark, 0.05);
			padding: 20px;
			display: flex;
			align-items: center;
			@include respond-above(ml) {
				padding: 30px;
			}
			@include respond-below(xl) {
				padding: 10px;
			}
			.WpdCheckoutProductInfo {
				display: inline-flex;
				align-items: center;
				margin-right: 10px;
				.WpdCheckoutProductIcon {
					width: 50px;
					min-width: 50px;
					height: 50px;
					display: inline-flex;
					@include respond-above(ml) {
						width: 55px;
						min-width: 55px;
						height: 55px;
					}
					@include respond-below(xl) {
						width: 40px;
						min-width: 40px;
						height: 40px;
					}
					@include respond-below(sm) {
						width: 30px;
						min-width: 30px;
						height: 30px;
					}
					object,
					img,
					svg {
						width: 100%;
					}
					.WpdProductTextIcon {
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						width: 100%;
						height: 100%;
						font-size: 1.25rem;
						font-weight: 700;
						text-align: center;
						background: linear-gradient(
							265deg,
							$info 0%,
							$info-lighten 100%
						);
						color: set-text-color($info);
						@include respond-below(xl) {
							font-size: 1.125rem;
						}
						@include respond-below(sm) {
							font-size: 1rem;
						}
					}
					&:not(:last-child) {
						margin-right: 20px;
						@include respond-above(ml) {
							margin-right: 25px;
						}
						@include respond-below(xl) {
							margin-right: 10px;
						}
					}
				}
				.WpdCheckoutProductDetails {
					display: flex;
					flex-direction: column;
					.WpdCheckoutProductTitle {
						font-size: 1.25rem;
						font-weight: 500;
						line-height: 1.1;
						color: $sidebar;
						letter-spacing: -0.5px;
						@include respond-above(ml) {
							font-size: 1.375rem;
						}
						@include respond-below(sm) {
							font-size: 1.125rem;
						}
						&:not(:last-child) {
							margin-bottom: 5px;
							@include respond-above(ml) {
								margin-bottom: 8px;
							}
							@include respond-below(sm) {
								margin-bottom: 0;
							}
						}
					}
					.WpdCheckoutProductSubTitle {
						font-size: 0.85rem;
						font-weight: 400;
						line-height: 1.1;
						letter-spacing: -0.5px;
						color: $checkout-secondary;
						@include respond-above(ml) {
							font-size: 1rem;
						}
						@include respond-below(xl) {
							font-size: 0.75rem;
						}
					}
				}
			}
			.WpdCheckoutProductPrice {
				font-size: 1.625rem;
				font-weight: 500;
				letter-spacing: -1.5px;
				color: $sidebar;
				margin-left: auto;
				display: inline-flex;
				align-items: center;
				@include respond-above(ml) {
					font-size: 1.75rem;
				}
				@include respond-below(xl) {
					font-size: 1.375rem;
				}
				@include respond-below(sm) {
					font-size: 1.25rem;
				}
				.main-price {
					font-size: 0.7em;
					text-decoration: line-through;
					color: #6c6c85;
					margin-right: 5px;
					display: inline-block;
					display: flex;
					align-items: center;
				}
				&:not(:last-child) {
					margin-right: 20px;
					@include respond-below(xl) {
						margin-right: 10px;
					}
				}
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		.WpdCheckoutProductHasMultiItem {
			display: flex;
			flex-direction: column;
			.WpdCheckoutProduct {
				margin-bottom: 0 !important;
				padding: 25px 15px !important;
			}
			.WpdCheckoutSubProductList {
				flex: 0 0 100%;
				padding: 0 0 15px;
				background: #fff;
				border-top: 2px solid rgba($dark, 0.05);
				border-radius: 0 0 5px 5px !important;
				box-shadow: 0 5px 10px rgba($dark, 0.12);
				overflow: hidden;
				display: flex;
				flex-wrap: wrap;
				@include transition();
				@include transition-properties(
					max-height,
					padding,
					border-width
				);
				.WpdCol {
					flex: 0 0 50%;
					max-width: 50%;
					min-height: 100%;
					padding: 8px 10px;
					border-right: 1px solid $light;
					border-bottom: 1px solid $light;
					@include respond-below(xs) {
						flex: 0 0 100%;
						max-width: 100%;
					}
				}
				.WpdCheckoutSubProduct {
					border-radius: 5px;
					padding: 0 10px;
					display: flex;
					align-items: center;
					position: relative;
					height: 100%;
					@include respond-above(ml) {
						padding: 15px;
					}
					@include respond-below(lg) {
						padding: 0;
					}
					.WpdCheckoutProductInfo {
						display: inline-flex;
						align-items: center;
						margin-right: 10px;
						.WpdCheckoutProductIcon {
							width: 30px;
							min-width: 30px;
							height: 30px;
							display: inline-flex;
							@include respond-below(lg) {
								width: 25px;
								min-width: 25px;
								height: 25px;
							}
							object,
							img,
							svg {
								width: 100%;
							}
							.WpdProductTextIcon {
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 50%;
								width: 100%;
								height: 100%;
								font-size: 1rem;
								font-weight: 700;
								text-align: center;
								background: linear-gradient(
									265deg,
									$info 0%,
									$info-lighten 100%
								);
								color: set-text-color($info);
								@include respond-below(xl) {
									font-size: 0.875rem;
								}
								@include respond-below(sm) {
									font-size: 0.75rem;
								}
							}
							&:not(:last-child) {
								margin-right: 15px;
								@include respond-above(ml) {
									margin-right: 20px;
								}
								@include respond-below(xl) {
									margin-right: 10px;
								}
							}
						}
						.WpdCheckoutProductDetails {
							display: flex;
							flex-direction: column;
							@include respond-below(md) {
								flex-direction: row;
								flex-wrap: wrap;
								align-items: center;
							}
							.WpdCheckoutProductTitle {
								font-size: 1rem;
								font-weight: 500;
								line-height: 1.1;
								color: $sidebar;
								letter-spacing: -0.5px;
								@include respond-above(ml) {
									font-size: 1.25rem;
								}
								@include respond-below(lg) {
									font-size: 0.875rem;
								}
								&:not(:last-child) {
									margin-bottom: 5px;
									@include respond-above(ml) {
										margin-bottom: 8px;
									}
									@include respond-below(md) {
										margin-bottom: 0;
										margin-right: 10px;
									}
								}
							}
							.WpdCheckoutProductSubTitle {
								font-size: 0.75rem;
								color: #5a657d;
								margin: 0;
							}
						}
					}
				}
			}
			.WpdCheckoutProductExpandButton {
				background: linear-gradient(90deg, $info, $info-lighten);
				margin-left: auto;
				margin-right: auto;
				padding: 5px 25px;
				font-size: 1rem;
				font-weight: 500;
				color: $white;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				box-shadow: 0 5px 10px rgba($white, 0.1);
				margin-top: -15px;
				z-index: 9;
				cursor: pointer;
				@include transition;
				@include transition-properties(width);
				.WpdIcon {
					font-size: 0.75rem;
					transform-origin: center center;
					display: inline-flex;
					margin-left: 10px;
					@include transition();
					@include transition-properties(transform);
				}
			}
			&.Collapsed {
				.WpdCheckoutSubProductList {
					max-height: 0;
					padding-top: 0;
					padding-bottom: 0;
					border-top: none;
				}
			}
			&:not(.Collapsed) {
				.WpdCheckoutProduct {
					border-radius: 5px 5px 0 0 !important;
				}
				.WpdCheckoutSubProductList {
					max-height: 1000px;
				}
				.WpdCheckoutProductExpandButton {
					.WpdIcon {
						transform: rotateX(180deg);
					}
				}
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
	.WpdCheckoutProductHighlightedWrapper {
		.WpdCheckoutProduct {
			background: transparent;
			border-radius: 5px;
			padding: 20px;
			display: flex;
			align-items: center;
			box-shadow: 0 15px 50px rgba(#25396f, 0.051);
			border: 1.5px dashed #d5d5d5;
			@include respond-above(ml) {
				padding: 30px;
			}
			@include respond-below(xl) {
				padding: 10px;
			}
			.WpdCheckbox {
				.WpdText {
					height: 20px;
					padding-left: 30px !important;
					@include respond-below(lg) {
						padding-left: 28px !important;
					}
				}
				input[type='checkbox'] {
					+ .WpdText:before {
						background: rgba($light, 0.05);
						border-color: rgba($secondary, 0.5);
					}
					&:checked + .WpdText:before {
						color: set-text-color($success-lighten);
						background: $success-lighten;
						border-color: $success-lighten;
					}
				}
			}
			.WpdCheckoutProductInfo {
				display: inline-flex;
				align-items: center;
				margin-right: 8px;
				.WpdCheckoutProductIcon {
					width: 50px;
					min-width: 50px;
					height: 50px;
					display: inline-flex;
					@include respond-above(ml) {
						width: 55px;
						min-width: 55px;
						height: 55px;
					}
					@include respond-below(xl) {
						width: 40px;
						min-width: 40px;
						height: 40px;
					}
					@include respond-below(lg) {
						width: 35px;
						min-width: 35px;
						height: 35px;
					}
					@include respond-below(sm) {
						width: 30px;
						min-width: 30px;
						height: 30px;
					}
					object,
					img,
					svg {
						width: 100%;
					}
					.WpdProductTextIcon {
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						width: 100%;
						height: 100%;
						font-size: 1.25rem;
						font-weight: 700;
						text-align: center;
						background: linear-gradient(
							265deg,
							$info 0%,
							$info-lighten 100%
						);
						color: set-text-color($info);
						@include respond-below(xl) {
							font-size: 1.125rem;
						}
						@include respond-below(lg) {
							font-size: 1rem;
						}
					}
					&:not(:last-child) {
						margin-right: 15px;
						@include respond-above(ml) {
							margin-right: 20px;
						}
						@include respond-below(xl) {
							margin-right: 10px;
						}
					}
				}
				.WpdCheckoutProductDetails {
					display: flex;
					flex-direction: column;
					.WpdCheckoutProductTitle {
						font-size: 1.25rem;
						font-weight: 500;
						line-height: 1.1;
						color: $sidebar;
						letter-spacing: -0.5px;
						@include respond-above(ml) {
							font-size: 1.375rem;
						}
						@include respond-below(lg) {
							font-size: 1.0625rem;
						}
						@include respond-below(sm) {
							font-size: 1.125rem;
						}
						&:not(:last-child) {
							margin-bottom: 5px;
							@include respond-above(ml) {
								margin-bottom: 8px;
							}
							@include respond-below(lg) {
								margin-bottom: 3px;
							}
							@include respond-below(sm) {
								margin-bottom: 0;
							}
						}
					}
					.WpdCheckoutProductSubTitle {
						font-size: 0.85rem;
						font-weight: 400;
						line-height: 1.1;
						letter-spacing: -0.5px;
						color: $checkout-secondary;
						@include respond-above(ml) {
							font-size: 1rem;
						}
						@include respond-below(xl) {
							font-size: 0.75rem;
						}
					}
				}
			}
			.WpdCheckoutProductPriceWrapper {
				margin-left: auto;
				display: flex;
				align-items: center;
				column-gap: 15px;
				flex-wrap: wrap;
				justify-content: center;
				row-gap: 8px;
				@include respond-below(lg) {
					row-gap: 6px;
				}
				.WpdCheckoutTogglerWrapper {
					user-select: none;
					display: flex;
					align-items: center;
					column-gap: 8px;
					cursor: pointer;
					.WpdCheckoutTogglerText {
						font-size: 1rem;
						font-weight: 400;
						line-height: 1.2;
						color: #5a657d;
						&.active {
							color: #090a52;
						}
						@include respond-below(lg) {
							font-size: 0.75rem;
						}
					}
					.WpdCheckoutToggler {
						input {
							display: none;
							& + .WpdCheckoutTogglerInner {
								height: 16px;
								width: 26px;
								border-radius: 20px;
								background-color: #3858e8;
								display: block;
								position: relative;
								&:before {
									position: absolute;
									content: '';
									height: 12px;
									width: 12px;
									display: block;
									background-color: white;
									border-radius: 20px;
									top: 2px;
									left: 2px;
									transition: all 0.2s linear;
								}
							}

							&:checked + .WpdCheckoutTogglerInner::before {
								left: 12px;
							}
						}
					}
				}
				.WpdCheckoutProductPrice {
					font-size: 1.625rem;
					font-weight: 500;
					letter-spacing: -1.5px;
					line-height: 1;
					color: $sidebar;
					display: inline-flex;
					align-items: flex-end;
					@include respond-above(ml) {
						font-size: 1.75rem;
					}
					@include respond-below(xl) {
						font-size: 1.375rem;
						margin-left: 0px;
					}
					@include respond-below(sm) {
						font-size: 1.25rem;
					}
					.main-price {
						font-size: 0.7em;
						text-decoration: line-through;
						color: #6c6c85;
						margin-right: 5px;
						display: flex;
						align-items: center;
					}
					.WpdPriceDuration {
						font-size: 0.6em;
						font-weight: 500;
						line-height: 1.4;
						color: #5a657d;
						display: inline-block;
						letter-spacing: 0px;
					}
				}
				&:not(:last-child) {
					margin-right: 15px;
					@include respond-below(xl) {
						margin-right: 10px;
					}
				}
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}

		&:not(&:last-of-type) {
			margin-bottom: 10px;
		}
	}
	.WpdCheckoutContentLeft {
		flex: 1;
		padding: 50px;
		background-color: $white;
		width: calc(100% - 830px);
		@include respond-above(ml) {
			width: calc(100% - 900px);
		}
		@include respond-below(hl) {
			width: calc(100% - 720px);
		}
		@include respond-below(xxl) {
			width: calc(100% - 650px);
			padding: 40px;
		}
		@include respond-below(xl) {
			width: calc(100% - 500px);
			padding: 30px;
		}
		@include respond-below(lg) {
			width: 50%;
			padding: 30px 20px;
		}
		@include respond-below(md) {
			width: 100%;
		}
		@include respond-below(sm) {
			padding: 30px 20px;
		}
		.WpdPaymentMethodSection {
			.WpdPaymentMethodSectionTitle {
				font-size: 1.375rem;
				font-weight: 500;
				line-height: 1.3;
				margin-bottom: 20px;
				color: $sidebar;
				@include respond-above(ml) {
					font-size: 1.5rem;
					margin-bottom: 30px;
				}
				@include respond-below(xl) {
					font-size: 1.25rem;
					margin-bottom: 15px;
				}
			}
			.WpdFormWrapper {
				.row {
					margin-bottom: -20px;
					@include respond-above(ml) {
						margin-bottom: -30px;
					}
					@include respond-below(xl) {
						margin-bottom: -15px;
						margin-left: -7.5px;
						margin-right: -7.5px;
					}
					> .col,
					> [class*='col-'] {
						margin-bottom: 20px;
						@include respond-above(ml) {
							margin-bottom: 30px;
						}
						@include respond-below(xl) {
							margin-bottom: 15px;
							padding-left: 7.5px;
							padding-right: 7.5px;
						}
						@include respond-between(md, lg) {
							flex: 0 0 100%;
							max-width: 100%;
						}
						.WpdFormGroup {
							&.WpdHasIcon {
								position: relative;
								.WpdFormIcon {
									position: absolute;
									top: 50%;
									left: 13px;
									transform: translateY(-50%);
									font-size: 0.9375rem;
									display: inline-flex;
									color: $checkout-secondary;
									@include respond-above(ml) {
										font-size: 1rem;
									}
								}
								.form-control {
									padding-left: 36px !important;
									&:focus {
										& + .WpdFormIcon {
											color: $checkout-success;
										}
									}
								}
							}
						}
					}
				}
			}
			&:not(:last-child) {
				margin-bottom: 20px;
				@include respond-above(ml) {
					margin-bottom: 50px;
				}
				@include respond-below(xl) {
					margin-bottom: 15px;
				}
			}
		}
		.WpdFormGroup {
			input.form-control {
				font-size: 1rem;
				border-color: $checkout-border-color;
				color: $dark;
				min-height: 50px;
				@include respond-above(ml) {
					min-height: 55px;
				}
				@include respond-below(xl) {
					min-height: 42px;
					height: 42px;
				}
				&::placeholder {
					color: $checkout-secondary;
				}
				&:focus {
					border-color: $checkout-success;
					box-shadow: none;
				}
				&:disabled {
					background-color: rgba($white, 0.05) !important;
					border-color: transparent;
					color: $white;
					padding: 0 30px;
					cursor: initial;
				}
			}
		}
		.WpdPaymentMethodSelector {
			display: flex;
			justify-content: space-between;
			@include respond-below(lg) {
				flex-wrap: wrap;
				margin-bottom: -10px;
			}
			.WpdRadio {
				width: calc(50% - 15px);
				min-width: 200px;
				@include respond-below(xxl) {
					min-width: 180px;
				}
				@include respond-below(xl) {
					width: calc(50% - 7.5px);
				}
				@include respond-below(lg) {
					margin-bottom: 10px;
				}
				@include respond-between(md, lg) {
					width: 100%;
				}
				@include respond-below(sm) {
					width: 100%;
				}
				.WpdDetails {
					min-height: 83px;
					width: 100%;
					border-radius: 5px;
					border: 1.5px solid $checkout-border-color;
					display: inline-flex;
					justify-content: flex-start;
					align-items: center;
					padding: 10px 40px 10px 20px;
					background: $light;
					position: relative;
					@include respond-above(ml) {
						padding: 10px 50px 10px 30px;
					}
					@include respond-below(hl) {
						min-height: 80px;
					}
					@include respond-below(xxl) {
						min-height: 70px;
						padding: 10px 35px 10px 15px;
					}
					@include respond-below(lg) {
						min-height: 60px;
						padding: 5px 30px 5px 15px;
					}
					@include respond-below(md) {
						min-height: 60px;
					}
					.WpdIcon {
						display: inline-flex;
						align-items: center;
						justify-content: center;
						color: $secondary;
						font-size: 2.5rem;
						@include respond-below(xxl) {
							font-size: 2.375rem;
						}
						@include respond-below(xl) {
							font-size: 2.25rem;
						}
						&:not(:last-child) {
							margin-right: 20px;
							@include respond-below(xxl) {
								margin-right: 15px;
							}
							@include respond-below(xl) {
								margin-right: 10px;
							}
						}
					}
					.WpdContent {
						display: inline-flex;
						flex-direction: column;
						justify-content: center;
						.WpdTitle {
							font-size: 1.25rem;
							font-weight: 700;
							color: $sidebar;
							line-height: 1;
							letter-spacing: -0.5px;
							@include respond-between(sm, xl) {
								font-size: 1.125rem;
							}
							&:not(:last-child) {
								margin-bottom: 7px;
								@include respond-above(ml) {
									margin-bottom: 8px;
								}
								@include respond-below(xl) {
									margin-bottom: 4px;
								}
							}
						}
						.WpdInfo {
							font-size: 0.85rem;
							font-weight: 400;
							color: $checkout-secondary;
							line-height: 1;
							letter-spacing: -0.5px;
						}
					}
				}
				input[type='radio'] {
					display: none;
					+ .WpdDetails:before {
						position: absolute;
						top: 15px;
						right: 15px;
						font-family: 'Wpd-Icon' !important;
						content: '\e91d';
						height: 20px;
						width: 20px;
						background: transparent;
						color: transparent;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 20px;
						font-size: 20px;
						border: 1px solid $checkout-border-color;
						@include respond-below(xxl) {
							top: 10px;
							right: 10px;
							height: 16px;
							width: 16px;
							font-size: 16px;
						}
						@include respond-below(xl) {
							top: 7px;
							right: 7px;
						}
					}
					&:checked {
						& + .WpdDetails {
							border-color: $checkout-success;
							background: $white;
							.WpdIcon {
								color: $checkout-success;
							}
							&:before {
								background: $white;
								border: none;
								color: $checkout-success;
							}
						}
					}
				}
			}
		}

		// react select style overwrite for dark mood
		.WpdCheckoutSelect__control {
			font-size: 1rem;
			background: transparent;
			border-color: $checkout-border-color;
			min-height: 50px;
			@include respond-above(ml) {
				min-height: 55px;
			}
			@include respond-below(xl) {
				min-height: 42px;
			}
			.WpdCheckoutSelect__value-container {
				padding: 0 20px !important;
				> * {
					margin-left: 0;
				}
				.WpdCheckoutSelect__single-value {
					color: $dark;
				}
				.WpdCheckoutSelect__placeholder {
					color: $checkout-secondary;
				}
				.WpdCheckoutSelect__input {
					input {
						color: $dark !important;
						&:disabled {
							background: transparent !important;
						}
					}
				}
			}
			.WpdCheckoutSelect__indicators {
				.WpdCheckoutSelect__loading-indicator {
					color: $sidebar;
				}
				.WpdCheckoutSelect__dropdown-indicator,
				.WpdCheckoutSelect__clear-indicator {
					color: $checkout-border-color !important;
				}
				.WpdCheckoutSelect__indicator-separator {
					background-color: $checkout-border-color !important;
				}
			}
			&--is-focused {
				border-color: $checkout-success;
				box-shadow: none !important;
			}
		}
		.WpdCheckoutSelect--is-disabled {
			filter: blur(0.2px);
			cursor: not-allowed;
			.WpdCheckoutSelect__control {
				background-color: rgba($checkout-border-color, 0.3);
				border-color: transparent;
			}
			.WpdCheckoutSelect__indicators {
				opacity: 0.7;
			}
		}
		.WpdCheckoutSelect__menu {
			border-radius: 0.25rem;
			z-index: 9 !important;
			.WpdCheckoutSelect__option--is-focused {
			}
		}
		// end react select style overwrite for dark mood

		.WpdCheckbox {
			.WpdText {
				color: $dark;
				font-weight: 400;
				a {
					color: $sidebar;
					font-style: italic;
					font-weight: 500;
					text-decoration: underline;
				}
			}
			input[type='checkbox'] {
				+ .WpdText:before {
					background: rgba($light, 0.05);
					border-color: rgba($secondary, 0.5);
				}
				&:checked + .WpdText:before {
					color: set-text-color($success-lighten);
					background: $success-lighten;
					border-color: $success-lighten;
				}
			}
		}
	}
	.WpdCheckoutContentRight {
		width: 830px;
		padding: 50px;
		@include respond-above(ml) {
			width: 900px;
		}
		@include respond-below(hl) {
			width: 720px;
		}
		@include respond-below(xxl) {
			width: 650px;
			padding: 40px;
		}
		@include respond-below(xl) {
			width: 500px;
			padding: 30px;
		}
		@include respond-below(lg) {
			width: 50%;
			padding: 30px 20px;
		}
		@include respond-below(md) {
			width: 100%;
			padding-top: 15px;
		}
		@include respond-below(sm) {
			padding: 30px 20px;
			padding-top: 10px;
		}
		.WpdUpSellSection {
			display: flex;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: -15px;
			@include respond-between(md, lg) {
				flex-wrap: wrap;
			}
			@include respond-below(md) {
				margin-left: -5px;
				margin-right: -5px;
			}
			@include respond-below(xs) {
				flex-wrap: wrap;
			}
			.WpdUpSell {
				display: flex;
				width: 100%;
				padding-left: 10px;
				padding-right: 10px;
				margin-bottom: 15px;
				flex: 1;
				max-width: 100%;

				@include respond-between(md, lg) {
					flex: 0 0 100%;
					max-width: 100%;
				}
				@include respond-below(md) {
					padding-left: 5px;
					padding-right: 5px;
				}
				@include respond-below(xs) {
					flex: 0 0 100%;
					max-width: 100%;
				}
				input {
					display: none;
					& + .WpdUpSellDetails {
						position: relative;
						background: $light;
						border: 1.5px solid $checkout-border-color;
						border-radius: 5px;
						padding: 30px 40px 20px 20px;
						display: flex;
						flex-direction: column;
						width: 100%;
						cursor: pointer;
						@include respond-below(xl) {
							padding: 25px 40px 15px 15px;
						}
						.WpdUpSellTitle {
							display: block;
							font-size: 0.875rem;
							line-height: 1.1;
							letter-spacing: -0.5;
							color: $dark;
							font-weight: 500;
							margin-bottom: 5px;
						}
						.WpdUpSellText {
							font-size: 0.8125rem;
							line-height: 1.4;
							letter-spacing: -0.5;
							color: $checkout-secondary;
						}
						.WpdUpSellRibbon {
							position: absolute;
							top: -5px;
							left: -5px;
							background: linear-gradient(
								90deg,
								#007cdb 0%,
								#00c0ff 100%
							);
							border-radius: 3px;
							min-height: 30px;
							min-width: 50px;
							display: inline-flex;
							justify-content: center;
							align-items: center;
							text-align: center;
							padding: 3px 5px;
							font-size: 0.6275rem;
							line-height: 1;
							font-weight: 700;
							color: $white;
							z-index: 1;
							@include respond-below(xl) {
								min-height: 25px;
							}
							&.hasImage {
								background: none;
								.savedValue {
									position: absolute;
									top: -6px;
									left: -5px;
									max-width: 260px;
								}
								.couponImage {
									margin-left: 30px;
								}
							}
						}
						&:after {
							font-family: 'Wpd-Icon' !important;
							content: '\e91d';
							position: absolute;
							right: 10px;
							top: 10px;
							height: 16px;
							width: 16px;
							min-width: 16px;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							border: 1.5px solid $checkout-border-color;
							background: transparent;
							color: transparent;
						}
					}
					&:checked {
						& + .WpdUpSellDetails {
							background: $white;
							border-color: $white;
							&:after {
								border-color: $white;
								background: $white;
								color: $checkout-success;
							}
						}
					}
				}
				&.hasSavedValue {
					input {
						& + .WpdUpSellDetails {
							border-color: #0434e0;
							border-width: 2px;
						}
					}
				}
			}
		}
		.WpdCheckedUpSell {
			background: $white;
			border-radius: 5px;
			box-shadow: 0 15px 50px rgba($dark, 0.05);
			padding: 20px;
			display: flex;
			align-items: center;
			border: 1.5px solid $checkout-success;
			position: relative;
			@include respond-above(ml) {
				padding: 30px;
			}
			@include respond-below(xl) {
				padding: 10px;
			}
			.WpdCheckedUpSellInfo {
				display: inline-flex;
				align-items: center;
				margin-right: 10px;
				.WpdCheckedUpSellLink {
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					bottom: 0;
				}
				.WpdCheckedUpSellIcon {
					width: 50px;
					min-width: 50px;
					height: 50px;
					display: inline-flex;
					@include respond-above(ml) {
						width: 55px;
						min-width: 55px;
						height: 55px;
					}
					@include respond-below(xl) {
						width: 40px;
						min-width: 40px;
						height: 40px;
					}
					@include respond-below(sm) {
						width: 30px;
						min-width: 30px;
						height: 30px;
					}
					object,
					img,
					svg {
						width: 100%;
					}
					&:not(:last-child) {
						margin-right: 20px;
						@include respond-above(ml) {
							margin-right: 25px;
						}
						@include respond-below(xl) {
							margin-right: 10px;
						}
					}
				}
				.WpdCheckedUpSellDetails {
					display: flex;
					flex-direction: column;
					.WpdCheckedUpSellTitle {
						font-size: 1.25rem;
						font-weight: 500;
						line-height: 1.1;
						color: $sidebar;
						letter-spacing: -0.5px;
						@include respond-above(ml) {
							font-size: 1.375rem;
						}
						@include respond-below(sm) {
							font-size: 1.125rem;
						}
						&:not(:last-child) {
							margin-bottom: 8px;
							@include respond-above(ml) {
								margin-bottom: 10px;
							}
							@include respond-below(sm) {
								margin-bottom: 5;
							}
						}
					}
					.WpdCheckedUpSellSubTitle {
						font-size: 0.85rem;
						font-weight: 400;
						line-height: 1.1;
						letter-spacing: -0.5px;
						color: $checkout-secondary;
						@include respond-above(ml) {
							font-size: 1rem;
						}
						@include respond-below(xl) {
							font-size: 0.75rem;
						}
					}
				}
			}
			.WpdCheckedUpSellChecker {
				margin-left: auto;
				font-size: 0.75rem;
				font-weight: 500;
				line-height: 1.1;
				color: $checkout-secondary;
				display: flex;
				align-items: center;
				&:before {
					font-family: 'Wpd-Icon' !important;
					content: '\e91d';
					height: 16px;
					width: 16px;
					min-width: 16px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					background: $white;
					color: $checkout-success;
					margin-right: 6px;
				}
			}
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			.WpdCheckedUpSellTooltip {
				position: absolute;
				top: 5px;
				right: 5px;
				display: inline-flex;
				color: lighten($secondary, 10%);
				font-size: 1rem;
				cursor: pointer;
			}
		}
		.WpdLicenseSection {
			background: transparent;
			border: 1.5px solid $checkout-border-color;
			border-radius: 5px;
			.WpdLicenseSectionTitle {
				font-size: 1.25rem;
				font-weight: 500;
				line-height: 1.3;
				color: $dark;
				padding: 20px;
				display: flex;
				justify-content: space-between;
				align-content: center;
				cursor: pointer;
				.WpdLicenseSectionTitleInfo {
					font-size: 0.75em;
					color: #9da9c7;
				}
				@include respond-above(ml) {
					font-size: 1.375rem;
					padding: 20px 30px;
				}
				@include respond-below(hl) {
					font-size: 1.125rem;
					padding: 15px 15px 15px 20px;
				}
				@include respond-below(xl) {
					font-size: 1rem;
					padding: 10px 10px 10px 15px;
				}
				.WpdCollapserToggler {
					height: 24px;
					width: 24px;
					min-width: 24px;
					border-radius: 7px;
					border: 1px solid $checkout-border-color;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 10px;
					line-height: 24px;
					color: $checkout-secondary;
					@include respond-above(ml) {
						width: 26px;
						min-width: 26px;
						height: 26px;
						font-size: 14px;
						line-height: 26px;
					}
					@include respond-below(xxl) {
						height: 20px;
						width: 20px;
						min-width: 20px;
						border-radius: 5px;
						line-height: 20px;
					}
				}
			}
			.WpdLicenseSectionBody {
				padding: 0 20px 20px 20px;
				@include respond-above(ml) {
					padding: 0 30px 30px 30px;
				}
				@include respond-below(hl) {
					padding: 0 15px 20px 20px;
				}
				@include respond-below(hl) {
					padding: 0 10px 15px 15px;
				}
				.WpdFormGroup {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					margin: 0;
					input.form-control {
						font-size: 1.125rem;
						background: rgba($checkout-primary, 0.1);
						border-color: rgba($checkout-primary, 0.2);
						height: 50px;
						color: $checkout-primary;
						border-radius: 5px 0 0 5px;
						border-right: none;
						@include respond-above(ml) {
							height: 50px;
							font-size: 1.25rem;
						}
						@include respond-below(xl) {
							height: 42px;
						}
						&::placeholder {
							color: rgba($checkout-primary, 0.7);
						}
						&:focus {
							border-color: rgba($checkout-primary, 0.5);
							box-shadow: none;
						}
					}
					.WpdApplyCouponButton {
						padding: 2px 30px;
						background: $checkout-primary;
						border-radius: 0 5px 5px 0;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						color: set-text-color($checkout-primary);
						font-size: 1.125rem;
						font-weight: 500;
						@include respond-above(ml) {
							font-size: 1.25rem;
						}
					}
				}

				.splitPayment {
					display: flex;
					align-items: center;
					column-gap: 1.5rem;
					row-gap: 0.5rem;
					.splitPayment-item {
						display: inline-block;
						input {
							display: none;
							& + span {
								display: inline-flex;
								align-items: center;
								font-size: 1.125rem;
								font-weight: 500;
								color: #7c8db5;
								position: relative;
								padding-left: 1.75rem;
								cursor: pointer;
								&:before {
									content: '';
									position: absolute;
									top: 50%;
									left: 0;
									transform: translateY(-50%);
									height: 1.125rem;
									aspect-ratio: 1/1;
									border-radius: 50%;
									border: 1px solid #d0d5dd;
								}
								&:after {
									content: '';
									position: absolute;
									top: 50%;
									left: 0.34375rem;
									transform: translateY(-50%);
									height: 0.4375rem;
									aspect-ratio: 1/1;
									border-radius: 50%;
									background: transparent;
								}
							}
							&:checked + span {
								&:before {
									background-color: #f9f5ff;
									border-color: #2fbf4e;
								}
								&:after {
									background: #2fbf4e;
								}
							}
						}
					}
				}
				.splitPaymentInfoWrapper {
					display: flex;
					flex-direction: column;
					margin-top: 1rem;
					.splitPaymentInfo {
						margin-top: 1rem;
						display: flex;
						align-items: center;
						.splitPaymentTitle {
							font-size: 1.25rem;
							font-weight: 500;
							line-height: 1.3;
							color: #8494ba;
							margin-right: auto;
						}
						.splitPaymentPreviousPrice {
							font-size: 1rem;
							font-weight: 500;
							line-height: 1.3;
							letter-spacing: -1.5px;
							text-decoration: line-through;
							margin-left: 1rem;
							color: #6c6c85;
						}
						.splitPaymentPrice {
							font-size: 1.5rem;
							font-weight: 500;
							line-height: 1.3;
							letter-spacing: -1.5px;
							margin-left: 1rem;
							color: #8494ba;
						}
						&.primary {
							.splitPaymentTitle,
							.splitPaymentPrice {
								color: #25396f !important;
							}
						}
					}
				}
			}
			&.Expanded {
				background: $white;
				border-color: $white;
				box-shadow: 0 15px 50px rgba($dark, 0.05);
				.WpdCollapserToggler {
					transform: rotateX(180deg);
					background: $light;
				}
			}
			&.Applied {
				background: $white;
				border-color: $white;
				box-shadow: 0 15px 50px rgba($dark, 0.05);
				.WpdLicenseAppliedScreen {
					padding: 20px;
					display: flex;
					align-items: center;
					@include respond-above(ml) {
						padding: 20px 30px;
					}
					@include respond-below(xl) {
						padding: 15px;
					}
					&:before {
						font-family: 'Wpd-Icon' !important;
						content: '\e91d';
						color: $checkout-success;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 20px;
						margin-right: 10px;
						font-size: 18px;
						@include respond-above(ml) {
							font-size: 20px;
							margin-right: 12px;
						}
					}
					.WpdText {
						font-size: 1.125rem;
						line-height: 1.3;
						font-weight: 400;
						color: $checkout-secondary;
						margin-right: 10px;
						@include respond-above(ml) {
							font-size: 1.25rem;
						}
						.WpdHighlighted {
							color: $checkout-success;
							font-weight: 500;
						}
					}
					.WpdRemoveButton {
						margin-left: auto;
					}
				}
			}
		}
		.WpdBuyerProtectionSection {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 10px 25px;
			border: 1.5px solid $checkout-border-color;
			border-radius: 5px;
			@include respond-above(ml) {
				padding: 30px;
			}
			@include respond-below(hl) {
				padding: 10px 20px;
			}
			@include respond-below(xl) {
				padding: 8px 12px;
			}
			.WpdBuyerProtectionIcon {
				margin-right: 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include respond-above(ml) {
					margin-right: 30px;
				}
				@include respond-below(xl) {
					margin-right: 12px;
				}
				svg,
				img {
					width: 40px;
					display: inline-flex;
					@include respond-above(ml) {
						width: 45px;
					}
					@include respond-below(hl) {
						width: 35px;
					}
					@include respond-below(xl) {
						width: 25px;
					}
				}
			}
			.WpdBuyerProtectionContent {
				.WpdBuyerProtectionTitle {
					font-size: 1.375rem;
					font-weight: 500;
					line-height: 1.3;
					color: $dark;
					@include respond-above(ml) {
						font-size: 1.5rem;
					}
					@include respond-below(hl) {
						font-size: 1.25rem;
					}
					@include respond-below(xl) {
						font-size: 1.125rem;
					}
					&:not(:last-child) {
						margin-bottom: 5px;
						@include respond-above(ml) {
							margin-bottom: 10px;
						}
						@include respond-below(hl) {
							margin-bottom: 3px;
						}
						@include respond-below(xl) {
							margin-bottom: 0;
						}
					}
				}
				.WpdBuyerProtectionInfo {
					font-size: 0.875rem;
					font-weight: 400;
					line-height: 1.3;
					color: $secondary;
					@include respond-above(ml) {
						font-size: 1rem;
					}
					@include respond-below(hl) {
						font-size: 0.75rem;
					}
					@include respond-below(xl) {
						font-size: 0.625rem;
					}
				}
			}
		}
		.WpdPurchaseButton {
			background: $checkout-success;
			min-height: 80px;
			padding: 20px 40px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			font-size: 2.375rem;
			font-weight: 700;
			line-height: 1.1;
			text-align: center;
			color: set-text-color($checkout-success);
			text-shadow: 0 4px 2px rgba($sidebar, 0.3);
			@include respond-above(ml) {
				min-height: 85px;
			}
			@include transition;
			@include respond-below(hl) {
				min-height: 70px;
				font-size: 2.25rem;
			}
			@include respond-below(xxl) {
				min-height: 60px;
				font-size: 2rem;
			}
			@include respond-below(xl) {
				min-height: 50px;
				font-size: 1.75rem;
			}
			&:hover,
			&:focus {
				background: darken($checkout-success, 3%);
				text-shadow: 0 4px 4px rgba($dark, 0.7);
				box-shadow: 0 10px 20px rgba($checkout-success, 0.3);
			}
		}
		.WpdRatingSection {
			background: transparent;
			border-radius: 5px;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			.WpdRattingQuoteIcon {
				position: absolute;
				top: 5px;
				left: 0;
				font-size: 25px;
				color: lighten($secondary, 10%);
			}
			.WpdReview {
				font-size: 0.875rem;
				line-height: 1.7;
				font-style: italic;
				color: $secondary;
				text-align: center;
				margin-top: 30px;
				@include respond-above(ml) {
					font-size: 1rem;
				}
				@include respond-below(xxl) {
					line-height: 1.5;
				}
				@include respond-below(xl) {
					letter-spacing: -0.1px;
					font-size: 0.75rem;
				}
			}
			.WpdRatingWrapper {
				display: flex;
				margin-top: 5px;
				.WpdRate {
					font-size: 1.25rem;
					line-height: 1;
					color: rgba($secondary, 0.5);
					@include respond-below(hl) {
						font-size: 1.125rem;
					}
					@include respond-below(xxl) {
						font-size: 1rem;
					}
					@include respond-below(xl) {
						font-size: 0.75rem;
					}
					&:not(:last-child) {
						margin-right: 5px;
						@include respond-above(ml) {
							margin-right: 7px;
						}
					}
					&.WpdFilled {
						color: $yellow-lighten;
					}
				}
			}
			.WpdRatingDepositor {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 7px;
				.WpdRatingDepositorImage {
					display: inline-flex;
					height: 45px;
					width: 45px;
					min-width: 45px;
					border-radius: 50%;
					background: rgba($secondary, 0.3);
					@include respond-above(ml) {
						height: 50px;
						width: 50px;
						min-width: 50px;
					}
					@include respond-below(xxl) {
						height: 40px;
						width: 40px;
						min-width: 40px;
					}
					@include respond-below(xxl) {
						height: 35px;
						width: 35px;
						min-width: 35px;
					}
					svg,
					img {
						display: inline-flex;
						border-radius: 50px;
						width: 100%;
						height: 100%;
					}
				}
				.WpdRatingDepositorInfo {
					display: flex;
					flex-direction: column;
					justify-content: center;
					.WpdRatingDepositorTitle {
						font-size: 1.125rem;
						font-weight: 500;
						color: $sidebar;
						line-height: 1.6;
						margin-top: 3px;
						@include respond-above(ml) {
							font-size: 1.25rem;
						}
						@include respond-below(xl) {
							font-size: 1rem;
							line-height: 1.4;
						}
					}
					.WpdRatingDepositorDesignation {
						font-size: 0.8125rem;
						font-weight: 500;
						color: $checkout-secondary;
						line-height: 1.6;
						@include respond-above(ml) {
							font-size: 0.875rem;
						}
						@include respond-below(xl) {
							line-height: 1.3;
						}
					}
				}
			}
		}
	}
}

// body header
.WpdContentAreaHeader {
    z-index: 1005;
    .WpdContentAreaHeaderNotice {
        background: $warning;
        padding: 15px 50px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        gap: 20px;
        color: $white;
        position: relative;
        @include respond-below(xxl) {
            padding: 15px 30px;
        }
        .WpdIcon {
            height: 40px;
            width: 40px;
            min-width: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $white;
            background: rgba($white, 0.05);
            border-radius: 100%;
            font-size: 1.0625rem;
        }
        .WpdText {
            font-size: 1.125rem;
            line-height: 1.25;
            text-align: center;
            @include respond-below(sm) {
                font-size: 1rem;
            }
        }
        .WpdCloseButton {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            font-size: 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: $white;
            @include respond-below(xxl) {
                right: 20px;
            }
        }
    }
    .WpdHeaderWrapper {
        padding: 30px 50px;
        display: flex;
        height: 110px;
        align-items: center;
        @include respond-below(xxl) {
            height: 80px;
        }
        @include respond-below(xxl) {
            padding: 20px 30px;
        }
        @include respond-below(sm) {
            padding: 20px 15px;
        }
        .WpdContentAreaHeaderLeft {
            .WpdSidebarToggler {
                height: 40px;
                width: 40px;
                min-width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $primary;
                color: $white;
                font-size: 1.25rem;
                outline: none;
                box-shadow: none;
                cursor: pointer;
                @include respond-below(xxl) {
                    height: 32px;
                    width: 32px;
                    min-width: 32px;
                }
            }
            img,
            svg {
                height: 40px;
                margin-left: auto;
                margin-right: auto;
                @include respond-below(xxl) {
                    height: 36px;
                }
            }
        }
        .WpdContentAreaHeaderRight {
            .WpdProfileControl {
                &Button {
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-right: 30px !important;
                    padding-top: 0;
                    padding-bottom: 0;
                    border: none;
                    cursor: pointer;
                    @include respond-below(sm) {
                        padding-right: 20px !important;
                    }
                    .WpdProfileImage {
                        height: 50px;
                        width: 50px;
                        min-width: 50px;
                        display: flex;
                        border-radius: 50px;
                        background: rgba($secondary, 0.3);
                        @include respond-below(xxl) {
                            height: 40px;
                            width: 40px;
                            min-width: 40px;
                        }
                        img {
                            display: inline-flex;
                            padding: 1px;
                            border-radius: 50px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .WpdProfileDetails {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-left: 15px;
                        @include respond-below(sm) {
                            display: none;
                        }
                        .WpdUserName {
                            font-size: 1rem;
                            font-weight: 400;
                            color: $primary;
                        }
                    }
                    &:after {
                        font-family: 'Wpd-Icon';
                        content: '\e900';
                        position: absolute;
                        border: none !important;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        font-size: 0.75rem;
                        color: $secondary;
                    }
                }
            }
        }
    }
}

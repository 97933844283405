// import files
@import '../mixins/button';

@keyframes loading {
    0% {
        transform-origin: center center;
        transform: rotate(15deg);
    }
    100% {
        transform-origin: center center;
        transform: rotate(360deg);
    }
}

.WpdButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    min-width: 90px;
    padding: 1px;
    z-index: 1;
    border-radius: 50px;
    @include transition;
    position: relative;
    .WpdButtonInner {
        padding: 3px 8px;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $white;
        color: $dark;
        width: 100%;
        height: 100%;
        min-height: 38px;
        border-radius: 50px;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color);
        .WpdIcon {
            height: 24px;
            width: 24px;
            min-width: 24px;
            background: $dark;
            color: $white;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            font-size: 8px;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
        }
        .WpdText {
            padding: 0 15px;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color);
            &:not(:first-child) {
                padding-left: 10px;
            }
            &:not(:last-child) {
                padding-right: 10px;
            }
            &.WpdLoadingText {
                display: flex;
                align-items: center;
                padding-left: 3px;
                img,
                svg {
                    width: 20px;
                    height: initial;
                    margin-right: 10px;
                    animation: loading 1s linear infinite;
                }
            }
        }
    }
    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($secondary, 0.2);
        border-radius: 50px;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, filter);
    }
}
a.WpdButton,
a.WpdSmallButton,
a.WpdLabelButton,
a.WpdIconButton,
a.WpdRemoveButton,
a.WpdBackButton,
label.WpdButton,
label.WpdSmallButton,
label.WpdLabelButton,
label.WpdIconButton,
label.WpdRemoveButton,
label.WpdBackButton,
button.WpdRemoveButton,
button.WpdIconButton,
button.WpdButton,
button.WpdLabelButton,
button.WpdBackButton,
button.WpdSmallButton {
    cursor: pointer;
}

// small button
.WpdSmallButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 30px;
    padding: 1px;
    z-index: 1;
    border-radius: 50px;
    @include transition;
    position: relative;
    .WpdButtonInner {
        padding: 3px 5px;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $white;
        color: $dark;
        width: 100%;
        height: 100%;
        min-height: 28px;
        border-radius: 50px;
        @include respond-below(hl) {
            font-size: 0.75rem;
        }
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, filter);
        .WpdIcon {
            display: inline-flex;
            border-radius: 50px;
            font-size: 12px;
            margin-left: 8px;
            margin-right: 8px;
            color: $secondary;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
        }
        .WpdText {
            padding: 0 10px;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
            &:not(:first-child) {
                padding-left: 0px;
            }
            &:not(:last-child) {
                padding-right: 0px;
            }
            &.WpdLoadingText {
                display: flex;
                align-items: center;
                padding-left: 3px;
                img,
                svg {
                    width: 20px;
                    height: initial;
                    margin-right: 10px;
                    animation: loading 1s linear infinite;
                }
            }
        }
    }
    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($secondary, 0.2);
        border-radius: 50px;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, filter);
    }
}

// label button
.WpdLabelButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    padding: 1px;
    z-index: 1;
    border-radius: 50px;
    @include transition;
    position: relative;
    .WpdButtonInner {
        padding: 2px;
        font-size: 0.75rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: $white;
        color: $dark;
        width: 100%;
        height: 100%;
        min-height: 22px;
        border-radius: 50px;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, filter);
        .WpdIcon {
            display: inline-flex;
            border-radius: 50px;
            font-size: 12px;
            margin-left: 4px;
            margin-right: 4px;
            color: $secondary;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
        }
        .WpdText {
            padding: 0 10px;
            @include transition($duration: 0.2s);
            @include transition-properties(background, color, filter);
            &:not(:first-child) {
                padding-left: 2px;
            }
            &:not(:last-child) {
                padding-right: 2px;
            }
            &.WpdLoadingText {
                display: flex;
                align-items: center;
                padding-left: 3px;
                img,
                svg {
                    width: 20px;
                    height: initial;
                    margin-right: 10px;
                    animation: loading 1s linear infinite;
                }
            }
        }
    }
    &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($secondary, 0.4);
        border-radius: 50px;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, filter);
    }
}

// icon button
.WpdIconButton {
    height: 20px;
    width: 20px;
    min-width: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: $secondary;
    color: $white;
    &.WpdOnlyIcon {
        font-size: 1.25rem;
        background: transparent;
        color: $secondary;
    }
}

// label button
.WpdRemoveButton {
    display: flex;
    align-items: center;
    @include transition($duration: 0.2s);
    @include transition-properties(background, color, border);
    .WpdRemoveIcon {
        height: 25px;
        width: 25px;
        min-width: 25px;
        border-radius: 7px;
        color: $secondary;
        border: 1px solid rgba($secondary, 0.1);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, border);
    }
    .WpdRemoveText {
        margin-left: 15px;
        font-size: 0.875rem;
        color: $dark;
        @include transition($duration: 0.2s);
        @include transition-properties(background, color, border);
    }
}

// back button
.WpdBackButton {
    display: inline-flex;
    align-items: center;

    .WpdIcon {
        background: $white;
        color: $secondary;
        height: 36px;
        width: 36px;
        min-width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        margin-right: 15px;
        font-size: 1rem;
        cursor: pointer;
        border: 1px solid rgba($dark, 0.1);
        @include transition();
        @include transition-properties(background, color, border-color);
    }
    .WpdText {
        font-weight: 700;
        line-height: 1;
        font-size: 1.375rem;
        margin-left: 10px;
        @include transition;
        @include respond-below(xs) {
            display: none;
        }
    }
    &:hover,
    &:focus {
        .WpdIcon {
            color: $info;
            border-color: $info;
        }
        .WpdText {
            color: $info;
        }
    }
}

.WpdPostGridButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    border: 1px solid rgba($secondary, 0.4);
    color: $secondary;
    background: transition;
    padding: 3px 15px;
    border-radius: 50px;
    font-size: 0.875rem;
    font-weight: 400;
    text-decoration: none;
    @include transition();
    @include transition-properties(background, border, color);
    &:hover,
    &:focus {
        background: $info;
        color: set-text-color($info);
        border-color: transparent;
    }
    &.WpdWhiteButton {
        border: 1px solid rgba($white, 0.4);
        color: $white;
        &:hover,
        &:focus {
            background: $info-lighten;
            color: set-text-color($info-lighten);
            border-color: transparent;
        }
    }
}

// button color control
@each $parent, $color in $theme-colors {
    @include button-emphasis-variant('.Wpd#{$parent}Button', $parent, $color);
}

// button color control
@each $parent, $color in $theme-colors {
    @include alert-emphasis-variant('.Wpd#{$parent}Alert', $parent, $color);
}

// hover color control
@each $parent, $color in $theme-colors {
    @include hover-emphasis-variant('.WpdHover#{$parent}', $parent, $color);
}

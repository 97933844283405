.WpdLoginBody {
	background: $sidebar;
	min-height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	.WpdLoginHeader {
		position: absolute;
		top: 40px;
		left: 35px;
		right: 35px;
		@include respond-below(xxl) {
			top: 20px;
			left: 25px;
			right: 25px;
		}
		.WpdSiteLogo {
			svg,
			img {
				height: 35px;
			}
			@include respond-below(xxl) {
				img,
				svg {
					height: 30px;
				}
			}
			@include respond-below(xl) {
				img,
				svg {
					height: 25px;
				}
			}
		}
	}
	.WpdLoginWrapper {
		max-width: 1400px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		@include respond-below(hl) {
			max-width: 1170px;
		}
		@include respond-below(xl) {
			max-width: 1024px;
		}
		@include respond-below(md) {
			max-width: 500px;
		}
		.WpdLoginContent,
		.WpdLoginImage {
			flex: 0 0 55%;
			max-width: 55%;
			padding: 130px 35px 50px;
			@include respond-below(xxl) {
				padding: 100px 30px 30px;
			}
		}
		@include respond-below(xl) {
			.WpdLoginContent {
				flex: 0 0 55%;
				max-width: 55%;
			}
			.WpdLoginImage {
				flex: 0 0 45%;
				max-width: 45%;
				padding: 110px 35px 50px 0;
			}
		}
		@include respond-below(lg) {
			.WpdLoginContent {
				flex: 0 0 60%;
				max-width: 60%;
			}
			.WpdLoginImage {
				flex: 0 0 40%;
				max-width: 40%;
			}
		}
		@include respond-below(md) {
			.WpdLoginContent {
				flex: 0 0 100%;
				max-width: 100%;
			}
			.WpdLoginImage {
				display: none !important;
			}
		}
		.WpdLoginContent {
			display: flex;
			align-items: center;
			.WpdLoginForm {
				background: $light;
				padding: 50px;
				width: 100%;
				border-radius: 25px;
				.form-control {
					border-color: rgba($secondary, 0.3);
					&:focus {
						border-color: #80bdff;
					}
				}
				@include respond-above(ml) {
					padding: 65px 70px;
				}
				@include respond-below(xxl) {
					padding: 40px;
				}
				@include respond-below(xs) {
					padding: 25px;
				}
				h2 {
					font-size: 2.25rem;
					font-weight: 700;
					margin-bottom: 30px;
					@include respond-below(xl) {
						font-size: 2rem;
					}
				}
			}
		}
		.WpdLoginImage {
			display: flex;
			align-items: center;
			flex: 0 0 45%;
		}
	}
}

.Wpd2FAForm {
	font-family: 'Poppins', sans-serif;
	background: $light;
	padding: 40px;
	width: 100%;
	border-radius: 16px;
	position: relative;
	.form-control {
		border-color: rgba($secondary, 0.3);
		&:focus {
			border-color: #80bdff;
		}
	}
	@include respond-above(ml) {
		padding: 56px;
	}
	@include respond-below(xxl) {
		padding: 32px;
	}
	@include respond-below(xs) {
		padding: 20px;
	}
	.Wpd2FABackButton {
		position: absolute;
		top: 16px;
		left: 16px;
		height: 36px;
		aspect-ratio: 1/1;
		border: 1px solid #dfe4f4;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		font-size: 12px;
		color: #4d4d4d;
		transition: color 0.075s ease-in-out;
		cursor: pointer;
	}
	.Wpd2FAHeadingWrapper {
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
		gap: 6px;
		.Wpd2FAHeadingIcon {
			height: 64px;
			aspect-ratio: 1/1;
			flex-shrink: 0;
			border: 1px solid #e4e8f5;
			background-color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 44px;
			columns: #21262c;
			border-radius: 16px;
		}
		.Wpd2FAHeadingTitle {
			font-size: 24px;
			font-weight: 500;
			line-height: 1;
			color: #21262c;
			margin-top: 12px;
		}
		.Wpd2FAHeadingSubTitle {
			font-size: 18px;
			font-weight: 500;
			line-height: 1.2;
			color: #21262c;
			margin-top: 12px;
		}
		.Wpd2FAHeadingInfo {
			font-size: 16px;
			font-weight: 400;
			line-height: 1.375;
			color: #4d4d4d;
		}
	}
	.Wpd2FAFormBody {
		display: flex;
		flex-direction: column;
		align-items: center;
		.Wpd2FAQrCodeWrapper {
			margin-top: 72px;
			width: 100%;
			border-radius: 8px;
			background: linear-gradient(to bottom, #ffffff, #f5f7fd);
			display: flex;
			justify-content: center;
			.Wpd2FAQrCode {
				margin-top: -42px;
				margin-bottom: 24px;
				width: 140px;
				aspect-ratio: 1/1;
				box-shadow: 0 12px 24px rgba(#000124, 10%);
				border-radius: 7px;
				img {
					width: 100%;
				}
			}
		}
		.Wpd2FASingleSetupKeyWrapper {
			display: flex;
			align-items: center;
			gap: 4px;
			justify-content: center;
			text-align: center;
			flex-wrap: wrap;
			max-width: 100%;
			.Wpd2FASingleSetupKeyLabel {
				font-size: 18px;
				font-weight: 500;
				line-height: 1.2;
				color: #4d4d4d;
				margin-right: 4px;
			}
			.Wpd2FASingleSetupKeyInner {
				display: flex;
				align-items: center;
				gap: 4px;
				justify-content: center;
				text-align: center;
				max-width: 100%;
				.Wpd2FASingleSetupKey {
					min-height: 24px;
					background-color: #e1f6ff;
					border: 1px solid #b4e0f3;
					padding: 1px 8px;
					display: inline-block;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.2;
					color: #0cb1f9;
					border-radius: 3px;
					word-wrap: anywhere;
					// text-align: left;
					// max-width: 100%;
				}
				.Wpd2FASingleSetupKeyCopyButton {
					cursor: pointer;
					height: 24px;
					aspect-ratio: 1/1;
					flex-shrink: 0;
					background-color: #e1f6ff;
					border: 1px solid #b4e0f3;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					color: #0cb1f9;
					border-radius: 3px;
				}
			}
		}
		.Wpd2FAMultipleKeyWrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;
			.Wpd2FAMultipleKeyTitle {
				font-size: 16px;
				font-weight: 500;
				line-height: 1.2;
				color: #21262c;
			}
			.Wpd2FAMultipleKeyInfo {
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;
				color: #4d4d4d;
			}
			.Wpd2FAMultipleKeyInner {
				width: 100%;
				border: 1px solid #dfe4f4;
				background-color: #ffffff;
				border-radius: 8px;
				padding: 24px;
				display: flex;
				text-align: left;
				gap: 4px 0px;
				position: relative;
				flex-wrap: wrap;
				.Wpd2FAMultipleKey {
					flex: 0 0 50%;
					font-size: 16px;
					font-weight: 400;
					line-height: 1.3;
					color: #4d4d4d;
				}
				.Wpd2FAMultipleKeyCopyButton {
					position: absolute;
					top: 8px;
					right: 8px;
					cursor: pointer;
					height: 24px;
					aspect-ratio: 1/1;
					flex-shrink: 0;
					background-color: #f5f7fd;
					border: 1px solid #e4e8f5;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					color: #4d4d4d;
					border-radius: 3px;
				}
			}
		}
		.Wpd2FAFormInputFieldWrapper {
			display: flex;
			flex-direction: column;
			gap: 12px;
			width: 100%;
			.Wpd2FAFormInputFieldLabel {
				font-size: 16px;
				font-weight: 400;
				line-height: 1.1;
				color: #21262c;
			}
			.Wpd2FAFormInputFieldInner {
				width: 100%;
				height: 56px;
				border: 1px solid #dfe4f4;
				background-color: #ffffff;
				border-radius: 8px;
				padding: 7px;
				display: flex;
				align-items: stretch;
				&.WpdHasError {
					color: #cf2124;
				}
				.Wpd2FAFormInputField {
					width: 100%;
					height: 100%;
					flex-grow: 1;
					padding: 0 8px;
					color: #21262c;
					font-size: 16px;
					font-weight: 400;
					::placeholder {
						color: #878da3;
					}
				}
				.Wpd2FAFormInputFieldButton {
					height: 100%;
					background: linear-gradient(to right, #02b9fb, #0084e0);
					color: #ffffff;
					padding: 1px 16px;
					border-radius: 6px;
					font-size: 16px;
					font-weight: 400;
					cursor: pointer;
					&:disabled {
						opacity: 0.6;
					}
				}
			}
			.Wpd2FAFormInputFieldError {
				font-size: 14px;
				font-weight: 400;
				line-height: 1.1;
				color: #cd181bf5;
			}
		}
		.Wpd2FAFormDivider {
			width: 100%;
			margin-top: 40px;
			margin-bottom: 40px;
			position: relative;
			&.WpdWithText {
				height: 14px;
				&:after {
					content: attr(data-text);
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					font-size: 16px;
					line-height: 1;
					text-transform: uppercase;
					color: #50556b;
					padding: 0 16px;
					background-color: $light;
				}
			}
			&:not(.WpdWithText) {
				height: 1px;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				border: 1px solid #e4e8f5;
			}
		}
		.Wpd2FAFormBodyLink {
			display: inline-block;
			color: #4d4d4d;
			font-size: 16px;
			line-height: 1.375;
			font-weight: 400;
			text-align: center;
			strong {
				font-weight: 500;
			}
			button,
			a {
				text-decoration: underline;
				text-underline-offset: 2px;
				color: #4d4d4d;
				&:hover {
					color: #3579f6;
					cursor: pointer;
				}
				&.highlight {
					text-decoration: none;
					color: #3579f6;
					&:hover {
						text-decoration: underline;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.mt-4\.5 {
	margin-top: 2rem;
}
.text-left {
	text-align: left !important;
}

.WpdAuthTitle {
	font-size: 3.125rem;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.03px;
	color: $primary;
	@include respond-below(xl) {
		font-size: 3rem;
	}
	@include respond-below(lg) {
		font-size: 2.75rem;
	}
	&:not(:last-child) {
		margin-bottom: 30px;
	}
}
.WpdAuthText {
	font-size: 1rem;
	line-height: 1.7;
	color: rgba($white, 0.7);
	&:not(:last-child) {
		margin-bottom: 50px;
	}
}

// google font
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// basic
* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
}
html {
    font-size: 16px;
    @include respond-below(xxl) {
        font-size: 14px;
    }
}
body {
    font-family: 'DM Sans', sans-serif;
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 0;
    min-height: 100vh;
    background: $white;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizeSpeed;
    scroll-behavior: smooth;
    -webkit-text-stroke: 0px !important;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba($sidebar, 0.5);
        background-image: linear-gradient(
            to right bottom,
            rgba($info, 0.05),
            rgba($info, 0.05)
        );
    }
    &::-webkit-scrollbar-thumb {
        background: darken($info, 10%);
    }
}

// component
ul,
li {
    list-style: none;
}
a {
    color: initial;
    transition: all 0.3s linear;
    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
    }
}
label {
    margin-bottom: 0;
}
button {
    vertical-align: middle;
    background: unset;
    &:hover,
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed !important;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 0;
}
ul,
ol {
    margin-bottom: 0;
}
.container-fluid {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    @include respond-below(xl) {
        padding-left: 30px;
        padding-right: 30px;
    }
}
